import { DISPLAY_MODE, inventorySuccessMessages } from "../../../constants";

export const dispatchFulfilled = (state, action) => {
  state.isLoading = false;
  state.dispatchitems = action.payload?.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const dispatchRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const viewDispatchFulfilled = (state, action) => {
  state.isLoading = false;
  state.dispatchitem = action.payload;
};
export const viewDispatchrejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const createDispatchFulfilled = (state, action) => {
  state.isLoading = false;
  if (!Array.isArray(action.payload)) {
    state.successMessage = action.payload;
  }
};

export const createDispatchRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const editDispatchFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = action.payload;
};

export const editDispatchRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const updateDispatchStatusFulfilled = (state, action) => {
  state.statusLoading = false;
  if (action.meta.arg.mode === DISPLAY_MODE.POPUP) {
    state.statusSuccessMessage =
      inventorySuccessMessages.updateDispatchStatus[action.meta.arg.status];
  } else {
    state.successMessage =
      inventorySuccessMessages.updateDispatchStatus[action.meta.arg.status];
  }
};

export const updateDispatchStatusRejected = (state, action) => {
  const { name, message } = action.error;
  state.statusLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const addDispatchNoteFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = action.payload;
};

export const addDispatchNoteRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
