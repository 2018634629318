import { createSlice } from "@reduxjs/toolkit";
import { FinanceState } from "./finance.model";
import { getBuyers, getQuoteById, getQuotes } from "./finance.action";
import {
  getBuyersFullfilled,
  getBuyersRejected,
  getQuoteByIdFulfilled,
  getQuoteByIdRejected,
  getQuotesFulfilled,
  getQuotesRejected,
} from "./finance.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: FinanceState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  quotes: [],
  totalRecords: 0,
  quote: null,
  buyers: [],
};

export const financeSlice = createSlice({
  name: "finance",
  initialState: initialState,
  reducers: {
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(getQuotes.pending, (state) => {
      state.quotes = [];
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getQuotes.fulfilled, (state, action) => {
      getQuotesFulfilled(state, action);
    }),
    builder.addCase(getQuotes.rejected, (state, action) => {
      getQuotesRejected(state, action);
    }),
    builder.addCase(getQuoteById.pending, (state) => {
      state.quotes = [];
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getQuoteById.fulfilled, (state, action) => {
      getQuoteByIdFulfilled(state, action);
    }),
    builder.addCase(getQuoteById.rejected, (state, action) => {
      getQuoteByIdRejected(state, action);
    }),
    builder.addCase(getBuyers.pending, (state) => {
      state.buyers = [];
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getBuyers.fulfilled, (state, action) => {
      getBuyersFullfilled(state, action);
    }),
    builder.addCase(getBuyers.rejected, (state, action) => {
      getBuyersRejected(state, action);
    })
  ),
});

export const { resetError, reset } = financeSlice.actions;
