import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { textFiledStyle } from "constants/styles";

type SelectWrapperPropType = {
  label: string;
  items: any[];
  itemLabelField?: string; // INFO: this is used to make it more dynamic so that __
  itemValueField?: string; // __ we can pass any data array and only need to tell the vale and label fields or keys;
  variant?: "filled" | "outlined" | "standard";
} & SelectProps;

const SelectWrapper = (props: SelectWrapperPropType) => {
  const {
    label,
    items,
    itemLabelField = "label",
    itemValueField = "value",
    variant,
    ...selectProps
  } = props;

  return (
    <FormControl fullWidth variant={variant ?? "filled"} sx={textFiledStyle}>
      <InputLabel>{label}</InputLabel>
      <Select {...selectProps}>
        {items.map((item) => (
          <MenuItem key={item[itemValueField]} value={item[itemValueField]}>
            <Typography variant="body1">{item[itemLabelField]}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectWrapper;
