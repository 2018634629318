import { Box, Stack, Typography } from "@mui/material";
import { pageHeading, pxToRem } from "./../../constants/styles";

type Props = {
  pageTitle?;
  children?: React.ReactNode;
  titleFontSize?: string;
  sx?: object;
};

const PageHeading = (props: Props) => {
  const { pageTitle, titleFontSize, children, sx } = props;
  const padBot = children ? 0 : `${pxToRem(11)}`;
  return (
    <Stack sx={{ ...pageHeading, ...sx }} pb={padBot}>
      {pageTitle && (
        <Typography
          component="h2"
          sx={{ ...pageHeading.headingStyle, fontSize: titleFontSize }}
        >
          {pageTitle}
        </Typography>
      )}
      {children && <Box>{children}</Box>}
    </Stack>
  );
};

export default PageHeading;
