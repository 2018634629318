import { RemoveCircleOutline } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import AvatarWrapper from "components/Avatar";
import ContactCardInfo from "components/ContactCardInfo";
import { pxToRem, gutterSpace, UserTileCardStyle } from "constants/styles";
import { ReactNode } from "react";

interface UserTileCardPropType {
  avatarSrc?: string;
  userName?: string;
  role?: string;
  badgeStatus?: "online" | "dnd" | "offline" | null;
  isBadgeOn?: boolean;
  isDeleteBtn?: boolean;
  handleRemovePersonnel?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: object;
  rightElement?: ReactNode;
}

const UserTileCard = (props: UserTileCardPropType) => {
  const {
    avatarSrc = "",
    userName = "",
    role = "",
    badgeStatus = "online",
    isBadgeOn = true,
    isDeleteBtn = false,
    handleRemovePersonnel,
    sx,
    rightElement,
  } = props;

  return (
    <ContactCardInfo sx={{ ...UserTileCardStyle, ...sx }}>
      <Stack
        flex={1}
        direction={"row"}
        alignItems={"center"}
        spacing={gutterSpace}
        minHeight={pxToRem(51)}
      >
        <AvatarWrapper
          // todo: add dynamic user src from user profile pic
          src={avatarSrc}
          userName={userName}
          // todo: need add dynamic online user status
          badgeStatus={isBadgeOn ? badgeStatus : null}
          avatarSize={"small"}
        />
        <Typography
          fontSize={pxToRem(14)}
          letterSpacing={pxToRem(0.269)}
          color="grey.50"
          flex={1}
        >
          {userName}
          {role && (
            <Typography fontSize={pxToRem(10)} color="grey.50" flex={1}>
              {role}
            </Typography>
          )}
        </Typography>
        {isDeleteBtn && (
          <IconButton onClick={handleRemovePersonnel} sx={{ color: "grey.50" }}>
            <RemoveCircleOutline />
          </IconButton>
        )}
        {rightElement}
      </Stack>
    </ContactCardInfo>
  );
};

export default UserTileCard;
