import { InputAdornment } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const EndAndorment = () => (
  <InputAdornment position="end">
    <ErrorIcon sx={{ color: "error.main" }} />
  </InputAdornment>
);

export default EndAndorment;
