import { useState, useEffect } from "react";
import { DaySelectorProps } from "types/daySelector";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const useDaySelector = ({ checkedDays = [], onSelect }: DaySelectorProps) => {
  const [selectedDay, setSelectedDay] = useState<string[]>(checkedDays);
  const handleDayClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    setSelectedDay((prevSelectedDays) => {
      if (checked) {
        return [...prevSelectedDays, value];
      } else {
        return prevSelectedDays.filter((day) => day !== value);
      }
    });
    onSelect(selectedDay);
  };

  useEffect(() => {
    onSelect(selectedDay);
  }, [onSelect, selectedDay]);

  return {
    days,
    selectedDay,
    setSelectedDay,
    handleDayClick,
  };
};
export default useDaySelector;
