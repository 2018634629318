import styled from "@emotion/styled";
import { Box, BoxProps } from "@mui/material";
import { ImageRounded } from "@mui/icons-material";
import { imageBoxStyle } from "constants/styles";

interface Props extends BoxProps {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  coverImg?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  ariaLabel?: string;
  children?: React.ReactNode;
  sx?: object;
}

const ImageWrapper = styled(Box)<Props>();

const ImageBox = (props: Props) => {
  const {
    width = undefined,
    height = undefined,
    maxWidth = undefined,
    maxHeight = undefined,
    coverImg,
    backgroundPosition,
    backgroundSize = "cover",
    children,
    ariaLabel,
    sx,
  } = props;
  return (
    <ImageWrapper
      sx={{
        backgroundImage: coverImg && `url(${coverImg})`,
        backgroundColor: !coverImg && "grey.900",
        backgroundPosition: backgroundPosition,
        backgroundSize: backgroundSize,
        maxWidth: maxWidth || undefined,
        maxHeight: maxHeight || undefined,
        ...imageBoxStyle,
        ...sx,
      }}
      width={width}
      height={height}
      aria-label={ariaLabel}
    >
      {!coverImg && <ImageRounded />}
      {children}
    </ImageWrapper>
  );
};

export default ImageBox;
