import { forwardRef } from "react";
import { Grid, Typography } from "@mui/material";
import { Button, DialogBox, ProfileMenu } from "components";
import { gutterSpace, linkButtonGrey50, pxToRem } from "constants/styles";
import { LIST_VIEW_HEADER_TYPE } from "../../../constants";
import { TodayTwoTone, AccountCircle } from "@mui/icons-material";
import SearchBar from "./SearchBar";
import useListViewHeader from "./useListViewHeader";
import PersonnelBulkImportDialog from "../BulkDialog";
import AssetBulkImportDialog from "../../inventory/asset/BulkDialog";
import ScanToUpdate from "containers/inventory/dispatch/ScanToUpdate";
import { SearchBarRef } from "types/finance";

interface ListViewHeaderPropType {
  pageHeading?: string;
  searchPlaceholder?: string;
  showFilterListIcon?: boolean;
  showSearchBar?: boolean;
  showProfileAvatar?: boolean;
  listViewHeaderType?: LIST_VIEW_HEADER_TYPE;
  createButton?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  rightColumn?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  handleFilter?: () => void;
  searchHandler?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  searchParam?: string;
  sx?: object;
  rightButton?: React.ReactNode;
  searchResultsList?: React.ReactNode;
  setShowSearchResultsPopper?: (value: boolean) => void;
}

const Header = forwardRef<SearchBarRef, ListViewHeaderPropType>(
  (props: ListViewHeaderPropType, ref) => {
    const {
      pageHeading,
      searchPlaceholder,
      showFilterListIcon,
      showSearchBar,
      showProfileAvatar,
      listViewHeaderType,
      createButton,
      rightColumn,
      handleFilter,
      searchHandler,
      searchParam,
      sx,
      rightButton,
      searchResultsList,
    } = props;

    const {
      showFilterListIcon: filterListIcon,
      showBulkImport,
      showCalendar,
      scanToUpdate,
      searchPlaceholder: searchPlaceholderText,
      showProfileAvatar: profileAvatar,
      showSearchBar: searchBar,
      isOpen,
      toggleModal,
      handleDispatchCalendar,
    } = useListViewHeader(listViewHeaderType);

    return (
      <Grid
        container
        alignItems="center"
        // spacing={gutterSpace}
        sx={{
          paddingTop: 0,
          marginBottom: "20px",
          alignItems: "center",
          ...sx,
        }}
      >
        {pageHeading && (
          <Grid item xs flex={1}>
            <Typography fontWeight={600} letterSpacing={pxToRem(0.15)}>
              {pageHeading}
            </Typography>
          </Grid>
        )}
        {(createButton || showBulkImport || showCalendar || scanToUpdate) && (
          <Grid item xs="auto">
            {createButton}
            {showBulkImport && (
              <>
                <Button
                  startIcon={<AccountCircle />}
                  variant="text"
                  sx={{ ...linkButtonGrey50, marginRight: gutterSpace }}
                  onClick={toggleModal}
                >
                  Bulk Import
                </Button>
                <DialogBox open={isOpen} onClose={toggleModal}>
                  {listViewHeaderType === LIST_VIEW_HEADER_TYPE.PERSONNEL ? (
                    <PersonnelBulkImportDialog onClose={toggleModal} />
                  ) : (
                    <AssetBulkImportDialog onClose={toggleModal} />
                  )}
                </DialogBox>
              </>
            )}
            {showCalendar && (
              <Button
                startIcon={<TodayTwoTone />}
                variant="text"
                sx={{ ...linkButtonGrey50, marginRight: gutterSpace }}
                onClick={handleDispatchCalendar}
              >
                Calendar
              </Button>
            )}
            {scanToUpdate && <ScanToUpdate />}
          </Grid>
        )}
        {(rightColumn || (showSearchBar ?? searchBar)) && (
          <Grid item xs sx={{ margin: 0, padding: 0 }}>
            {rightColumn}
            {(showSearchBar ?? searchBar) && (
              <SearchBar
                showSearchIcon
                showFilterListIcon={showFilterListIcon ?? filterListIcon}
                handleFilter={handleFilter}
                placeholder={searchPlaceholder || searchPlaceholderText}
                searchHandler={searchHandler}
                searchParam={searchParam}
                ref={ref}
              >
                {searchResultsList}
              </SearchBar>
            )}
          </Grid>
        )}
        {rightButton && (
          <Grid item xs="auto" display={"flex"} pl={gutterSpace}>
            {rightButton}
          </Grid>
        )}
        {(showProfileAvatar ?? profileAvatar) && (
          <Grid item xs="auto" display={"flex"} pl={gutterSpace}>
            <ProfileMenu />
          </Grid>
        )}
      </Grid>
    );
  }
);

export default Header;
