import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  id: string;
  value: string;
  sx?: object;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, id, sx, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== id}
      id={`full-width-tabpanel-${id}`}
      aria-labelledby={`full-width-tab-${id}`}
      {...other}
      sx={{ ...sx }}
    >
      {value === id && <Box>{children}</Box>}
    </Box>
  );
};

export default TabPanel;
