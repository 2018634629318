import { createSlice } from "@reduxjs/toolkit";

import { authenticateCode } from "./authenticateCode.action";
import { AUTHENTICATE_CODE } from "./authenticateCode.model";
import {
  authenticateCodeFulfilled,
  authenticateCodeRejected,
} from "./authenticateCode.reducers";

const initialState: AUTHENTICATE_CODE = {
  isLoading: false,
  error: "",
  success: false,
};

export const authenticateCodeSlice = createSlice({
  name: "resetPassword",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(authenticateCode.pending, (state, action) => {
      state.isLoading = true;
    }),
    builder.addCase(authenticateCode.fulfilled, (state, action) => {
      authenticateCodeFulfilled(state, action);
    }),
    builder.addCase(authenticateCode.rejected, (state, action) => {
      authenticateCodeRejected(state, action);
    })
  ),
});
