import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { uploadAssetBulkImportFile } from "redux/slices/inventory/inventory.action";
import { resetSuccess } from "redux/slices/inventory/inventory.slice";
import { AppDispatch, RootState } from "redux/store";
import useText from "utils/hooks/useText";

interface BulkDialogHook {
  fileName: string;
  errorFile: boolean;
  filenameHandler: (e) => void;
  dropzoneHandler: (e) => void;
  title: string;
  note: string;
  infoNote: string;
  description: string;
  confirm: string;
  browse: string;
  cancel: string;
  handleFileSubmit: () => void;
  isLoading: boolean;
}

const useBulkDialog = (onClose): BulkDialogHook => {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState(false);
  const { title, note, infoNote, description } = useText("bulkImport");
  const { confirm, browse, cancel } = useText("buttons");
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.asset);

  const filenameHandler = (e) => {
    const selectedFilename = e.target.files[0].name;
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    checkFiletype(selectedFilename);
  };

  const dropzoneHandler = (e) => {
    //getting file name
    const selectedFilename = e[0].name;
    checkFiletype(selectedFilename);
  };

  const checkFiletype = (selectedFilename) => {
    //getting file extension
    const selectedFileType = selectedFilename.split(".").pop();

    const allowedTypes = ["csv"];

    if (!allowedTypes.includes(selectedFileType)) {
      setFileName("File Type Not allowed");
      setErrorFile(true);
      return false;
    } else {
      setFileName(selectedFilename);
      setErrorFile(false);
      return true;
    }
  };

  const history = useHistory();

  const handleFileSubmit = () => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadAssetBulkImportFile({ formData: formData }))
      .then(() => {
        onClose();
      })
      .catch((error) => {
        // Handle error if uploadAssetBulkImportFile fails
        console.error("Error:", error);
      });
  };

  return {
    fileName,
    errorFile,
    filenameHandler,
    dropzoneHandler,
    title,
    note,
    infoNote,
    description,
    confirm,
    browse,
    cancel,
    handleFileSubmit,
    isLoading,
  };
};

export default useBulkDialog;
