import { Breakpoint, Dialog, DialogProps } from "@mui/material";
import { dialogStyle } from "constants/styles";
import { ReactNode } from "react";

type DialogPropsType = {
  children?: ReactNode;
  open?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  onClose?: (open: boolean) => void;
  size?: false | Breakpoint;
  sx?: object;
} & Partial<DialogProps>;

const DialogBox = (props: DialogPropsType) => {
  const {
    children,
    open = null,
    onClose = null,
    fullScreen = false,
    fullWidth,
    size,
    sx,
    ...otherProps
  } = props;

  const handleClose = () => {
    onClose(open);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        sx={{ ...dialogStyle, ...sx }}
        maxWidth={size}
        {...otherProps}
      >
        {/* note: for more info -> https://mui.com/material-ui/react-dialog/#customization */}
        {children}
      </Dialog>
    </>
  );
};

export default DialogBox;
