import styled from "@emotion/styled";
import { Box, BoxProps, Card, Typography } from "@mui/material";
import { ImageBox, StatusFlag } from "components";
import { listingCardStyle, pxToRem } from "constants/styles";

interface Props extends BoxProps {
  coverImg?: string;
  title?: string;
  subTitle?: string;
  profitCenter?: string;
  stockNumber?: number;
  pricing?: number | string;
  flagStatus?: number;
}

const ListingCardWrapper = styled(Card)<Props>();
const Header = styled(Box)<Props>();
const Content = styled(Box)<Props>();
const Footer = styled(Box)<Props>();

const ListingCard = (props: Props) => {
  const {
    coverImg,
    title,
    subTitle,
    profitCenter,
    pricing,
    flagStatus,
    stockNumber,
  } = props;
  return (
    <ListingCardWrapper sx={listingCardStyle}>
      <Header sx={listingCardStyle.Header}>
        {/* PC */}
        {profitCenter && (
          <Typography component={"span"}>{profitCenter}</Typography>
        )}
        {/* stock # */}
        {stockNumber && (
          <Typography component={"span"}>#{stockNumber}</Typography>
        )}
      </Header>
      <Content sx={listingCardStyle.Content}>
        {/* cover image */}
        <ImageBox coverImg={coverImg} height={pxToRem(208)} />
        {/* title */}
        {title && (
          <Typography component={"h5"} className="heading">
            {title}
          </Typography>
        )}
        {/* sub title */}
        {subTitle && (
          <Typography component={"span"} className="assetType">
            {subTitle}
          </Typography>
        )}
      </Content>
      <Footer sx={listingCardStyle.Footer}>
        {/* pricing*/}
        {pricing && (
          <Typography component={"h5"} className="pricing">
            ${pricing.toLocaleString()}
          </Typography>
        )}
        {/* flag status */}
        {flagStatus && <StatusFlag flagStatus={3} />}
      </Footer>
    </ListingCardWrapper>
  );
};

export default ListingCard;
