import { Box, Typography, styled } from "@mui/material";
import { pxToRem } from "constants/styles";

const Wrapper = styled(Box)`
  border-radius: ${pxToRem(4)};
  padding: ${pxToRem(6, 0, 6, 16)};
  background: rgba(91, 91, 91, 0.04);
  width: 100%;
  max-height: ${pxToRem(43)};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LocationBox = (props) => {
  const location =
    props.dataSource?.row.tenantName || "Evansville, Springfield";
  return (
    <Wrapper sx={{ padding: pxToRem(8, 16) }}>
      <Typography
        component="p"
        sx={{
          fontSize: pxToRem(10),
          fontWeight: "400",
          lineHeight: pxToRem(14),
          color: "greyVariantAlt.700",
        }}
      >
        Location
      </Typography>
      <Typography
        component="p"
        sx={{
          fontSize: pxToRem(14),
          fontWeight: "400",
          lineHeight: pxToRem(17),
          color: "greyVariantAlt.700",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {location}
      </Typography>
    </Wrapper>
  );
};

export default LocationBox;
