import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Skeleton } from "@mui/material";

type OnChangeParameterType = {
  target: {
    name: string;
    value: string;
  };
};

interface TinyMCEFieldProps {
  name: string;
  label?: string;
  error?: boolean;
  touched?: boolean;
  value?: string;
  onChange: ({ target: { name, value } }: OnChangeParameterType) => void;
}

const editorOptions = {
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ],
  toolbarOptions: `undo redo | blocks | 
    bold italic forecolor | alignleft aligncenter 
    alignright alignjustify | bullist numlist outdent indent | 
    removeformat | code | image | help`,
  contentStyleOptions:
    "body { font-family:Gantari, sans-serif; font-size:14px }",
};

const { plugins, toolbarOptions, contentStyleOptions } = editorOptions;

const TextEditor: React.FC<TinyMCEFieldProps> = ({
  name,
  label,
  value,
  error,
  touched,
  onChange,
}) => {
  const [editorIsLoading, setEditorIsLoading] = useState(true);

  const handleEditorChange = (value: string) => {
    onChange({ target: { name, value } });
  };

  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      {editorIsLoading && (
        <Skeleton variant="rectangular" width="100%" height={500} />
      )}
      <Editor
        value={value}
        init={{
          height: 500,
          menubar: false,
          plugins,
          toolbar: toolbarOptions,
          content_style: contentStyleOptions,
        }}
        onInit={() => setEditorIsLoading(false)}
        onEditorChange={handleEditorChange}
      />
      {touched && error ? <div className="error">{error}</div> : null}
    </div>
  );
};

export default TextEditor;
