import SelectAllRounded from "@mui/icons-material/SelectAllRounded";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import CustomChip from "components/CustomChip";
import {
  chipGeneratorStyle,
  gutterSpace,
  pxToRem,
  themeColorHex,
} from "constants/styles";
import useChipGenerator from "./useChipGenerator";

type ColorBoxProps = {
  colors?: string;
  sx?: object;
  isPreviewMode?: boolean;
};

type ChipGeneratorProps = {
  onSelect: (selectedValue: string) => void;
  label?: string;
  sx?: SxProps;
  customChipSx?: SxProps;
};

// Color Box Atom [Start]
const ColorBox = ({ colors, sx, isPreviewMode = false }: ColorBoxProps) => {
  let colorStyle = colors?.split(", ");

  return (
    <Box
      sx={{
        backgroundColor: colorStyle?.[0],
        color: colorStyle?.[1],
        ...(isPreviewMode && chipGeneratorStyle.previewBox),
        ...sx,
      }}
    >
      <Check />
      {isPreviewMode && (
        <Typography fontWeight={600}>Default Font Color</Typography>
      )}
    </Box>
  );
};
// Color Box Atom [END]

const ChipGenerator = ({
  onSelect,
  label,
  sx,
  customChipSx,
}: ChipGeneratorProps) => {
  const {
    colorSet,
    open,
    value,
    anchorEl,
    checkedFontColor,
    handleClick,
    handleClose,
    handleChange,
    handleFontChange,
  } = useChipGenerator(onSelect);

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={pxToRem(7)}
        lineHeight={2.5}
        sx={{ ...sx }}
      >
        <Box>
          <CustomChip colors={value} label={label} sx={customChipSx} />
        </Box>
        <IconButton
          onClick={handleClick}
          sx={{ padding: pxToRem(4), ml: pxToRem(6) }}
        >
          <Edit sx={{ width: pxToRem(16), height: pxToRem(16) }} />
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={chipGeneratorStyle}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={gutterSpace}
          sx={chipGeneratorStyle.header}
        >
          <Box display={"flex"} alignItems={"center"} gap={gutterSpace}>
            <SelectAllRounded />
            <Typography>Select Color</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Stack direction={"row"} spacing={pxToRem(24)}>
          <Box>
            <Typography sx={chipGeneratorStyle.subHeading}>
              Background
            </Typography>
            <Stack
              direction={"row"}
              spacing={pxToRem(3)}
              maxWidth={pxToRem(260)}
            >
              <FormControl>
                <RadioGroup
                  name="radio-colorBoxes"
                  row
                  value={value}
                  onChange={handleChange}
                  sx={{ gap: pxToRem(3) }}
                >
                  {colorSet.map((item, index) => (
                    <FormControlLabel
                      key={`box-${index}`}
                      value={`${item}`}
                      control={<Radio />}
                      label={<ColorBox colors={`${item}`} />}
                      sx={chipGeneratorStyle.radioBox}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
            <Typography sx={chipGeneratorStyle.subHeading} mt={pxToRem(30)}>
              Font Color
            </Typography>
            <FormControl>
              <RadioGroup
                name="radio-blackWhite"
                row
                onChange={handleFontChange}
                sx={{ gap: pxToRem(3) }}
              >
                <FormControlLabel
                  value="grey.50"
                  control={<Radio checked={checkedFontColor === "grey.50"} />}
                  label={<ColorBox colors="grey.50, grey.A400" />}
                  sx={chipGeneratorStyle.radioBox}
                />
                <FormControlLabel
                  value="grey.A400"
                  control={<Radio checked={checkedFontColor === "grey.A400"} />}
                  label={
                    <ColorBox
                      colors="grey.A400, grey.50"
                      sx={{
                        border: `${pxToRem(1)} solid  ${themeColorHex(
                          "greyVariantAlt[900]"
                        )}`,
                      }}
                    />
                  }
                  sx={chipGeneratorStyle.radioBox}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <Typography sx={chipGeneratorStyle.subHeading}>Preview</Typography>
            <ColorBox colors={value} isPreviewMode={true} />
          </Box>
        </Stack>
      </Menu>
    </>
  );
};

export default ChipGenerator;
