import text from "../text.json";

export type TextType = typeof text;

type ExtractValueType<T, K extends keyof T> = T[K];

function useText<K extends keyof TextType>(
  component: K
): ExtractValueType<TextType, K> {
  const value = text[component];
  return value;
}

export default useText;
