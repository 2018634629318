import { createSlice } from "@reduxjs/toolkit";
import { DispatchState } from "./dispatch.model";
import {
  updateDispatchStatus,
  createDispatch,
  editDispatch,
  getAllDispatchItems,
  getDispatchItem,
  addDispatchNote,
  getDispatchItemsByMonth,
} from "./dispatch.action";
import {
  dispatchFulfilled,
  dispatchRejected,
  viewDispatchFulfilled,
  viewDispatchrejected,
  createDispatchFulfilled,
  createDispatchRejected,
  addDispatchNoteFulfilled,
  addDispatchNoteRejected,
  updateDispatchStatusFulfilled,
  updateDispatchStatusRejected,
  editDispatchFulfilled,
  editDispatchRejected,
} from "./dispatch.reducer";

const initialState: DispatchState = {
  isLoading: false,
  statusLoading: false,
  error: { title: "", description: "" },
  dispatchitems: [],
  dispatchitem: null,
  successMessage: "",
  statusSuccessMessage: "",
  totalRecords: null,
};

export const dispatchSlice = createSlice({
  name: "dispatch",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    resetDispatch: (state) => {
      state.dispatchitem = null;
    },
    resetSuccess: (state) => {
      state.successMessage = "";
      state.statusSuccessMessage = "";
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDispatchItems.pending, (state, action) => {
        state.isLoading = true;
        state.dispatchitems = [];
        state.successMessage = "";
        state.error.title = "";
        state.error.description = "";
      })
      .addCase(getAllDispatchItems.fulfilled, (state, action) => {
        dispatchFulfilled(state, action);
      })
      .addCase(getAllDispatchItems.rejected, (state, action) => {
        dispatchRejected(state, action);
      })
      .addCase(getDispatchItem.pending, (state, action) => {
        state.isLoading = true;
        state.successMessage = "";
        state.error.title = "";
        state.error.description = "";
      })
      .addCase(getDispatchItem.fulfilled, (state, action) => {
        viewDispatchFulfilled(state, action);
      })
      .addCase(getDispatchItem.rejected, (state, action) => {
        viewDispatchrejected(state, action);
      })
      .addCase(createDispatch.pending, (state, action) => {
        state.isLoading = true;
        state.successMessage = "";
        state.error.title = "";
        state.error.description = "";
      })
      .addCase(createDispatch.fulfilled, (state, action) => {
        createDispatchFulfilled(state, action);
      })
      .addCase(createDispatch.rejected, (state, action) => {
        createDispatchRejected(state, action);
      })
      .addCase(editDispatch.pending, (state, action) => {
        state.isLoading = true;
        state.successMessage = "";
        state.error.title = "";
        state.error.description = "";
      })
      .addCase(editDispatch.fulfilled, (state, action) => {
        editDispatchFulfilled(state, action);
      })
      .addCase(editDispatch.rejected, (state, action) => {
        editDispatchRejected(state, action);
      })
      .addCase(addDispatchNote.pending, (state, action) => {
        state.isLoading = true;
        state.error.title = "";
        state.error.description = "";
      })
      .addCase(addDispatchNote.fulfilled, (state, action) => {
        addDispatchNoteFulfilled(state, action);
      })
      .addCase(addDispatchNote.rejected, (state, action) => {
        addDispatchNoteRejected(state, action);
      })
      .addCase(updateDispatchStatus.pending, (state, action) => {
        state.statusLoading = true;
        state.successMessage = "";
        state.statusSuccessMessage = "";
        state.error.title = "";
        state.error.description = "";
      })
      .addCase(updateDispatchStatus.fulfilled, (state, action) => {
        updateDispatchStatusFulfilled(state, action);
      })
      .addCase(updateDispatchStatus.rejected, (state, action) => {
        updateDispatchStatusRejected(state, action);
      })
      .addCase(getDispatchItemsByMonth.pending, (state, action) => {
        state.isLoading = true;
        state.dispatchitems = [];
        state.successMessage = "";
        state.error.title = "";
        state.error.description = "";
      })
      .addCase(getDispatchItemsByMonth.fulfilled, (state, action) => {
        dispatchFulfilled(state, action);
      })
      .addCase(getDispatchItemsByMonth.rejected, (state, action) => {
        dispatchRejected(state, action);
      });
  },
});

export const { reset, resetSuccess, resetError, resetDispatch, setError } =
  dispatchSlice.actions;
