import {
  Alert,
  AlertProps,
  AlertTitle,
  Collapse,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import styled from "@emotion/styled";
import { pxToRem } from "constants/styles";
import { useEffect, useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid-premium";
import { SUCCESS_MESSAGE_TIMEOUT } from "constants/index";

interface Props extends AlertProps {
  alertTitle?: String;
  alertMessage?: String | String[];
  children?: React.ReactNode;
  isFloating?: boolean;
  handleClick?: () => void;
  setCloseTimer?: "slow" | "medium" | "fast";
}

interface AlertBoxProps extends Props {
  openState?: boolean;
  setOpenState?: any;
}

const AlertBox = styled(Alert)<Props>();
const floatingStyle = {
  position: "fixed",
  width: `calc(100% - ${pxToRem(355)})`,
  margin: 0,
  right: pxToRem(15),
  top: pxToRem(25),
  zIndex: 2000,
};
const timeoutBar = {
  width: "100%",
  position: "absolute",
  left: 0,
  bottom: 0,
};
const closeTimer = {
  slow: 100,
  medium: 50,
  fast: 23,
};

const AlertCard: React.FC<AlertBoxProps> = ({
  alertMessage,
  alertTitle,
  children,
  isFloating = true,
  openState,
  setOpenState,
  setCloseTimer,
  ...otherProps
}) => {
  const [progress, setProgress] = useState(100);
  const [open, setOpen] = useState(openState);

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  const handleAlertClose = () => {
    if (!setCloseTimer) {
      setOpen(false);
      if (setOpenState) {
        setOpenState(false);
      }
    }
  };

  useEffect(() => {
    if (setCloseTimer) {
      const interval = setInterval(() => {
        setProgress((prevProgress) =>
          Math.max(prevProgress - (1 / closeTimer[setCloseTimer]) * 100, 0)
        );
        if (progress === 0) {
          setTimeout(() => {
            setOpen(false);
          }, 500);
        }
      }, 100);
      return () => {
        clearInterval(interval);
      };
    }
  }, [setCloseTimer, progress]);

  useEffect(() => {
    if (otherProps.severity === "success") {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 1000 * SUCCESS_MESSAGE_TIMEOUT);

      // INFO: Clean up the timeout on component unmount
      return () => clearTimeout(timer);
    }
  }, [otherProps.severity]);

  return (
    <Collapse in={open}>
      <AlertBox
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleAlertClose}
          >
            <GridCloseIcon fontSize="inherit" />
          </IconButton>
        }
        {...otherProps}
        sx={{
          ...(isFloating && floatingStyle),
          mt: 5,
        }}
        data-testid="alert-card"
      >
        {children || (
          <>
            {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
            {Array.isArray(alertMessage) ? (
              <ul className="m-0">
                {alertMessage.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            ) : (
              <Typography component="p">{alertMessage}</Typography>
            )}
          </>
        )}
        {setCloseTimer && (
          <LinearProgress
            variant="buffer"
            value={progress}
            color="error"
            valueBuffer={100}
            sx={timeoutBar}
          />
        )}
      </AlertBox>
    </Collapse>
  );
};
export default AlertCard;
