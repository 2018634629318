import { DispatchStatus } from "constants/inventory";

export const personnelSuccessMessages = {
  personnelCreated: "Success! Personnel Created",
  personnelUpdated: "Success! Personnel Updated",
  notifyEmailSuccess: "Notification email sent successfully.",
};

export const inventorySuccessMessages = {
  createAsset: "The Asset was created successfully",
  editAsset: "The Asset was updated successfully",
  createTemplate: "The template was created successfully",
  scanSuccess: "Success! Scan completed with no errors",
  assignRole: "Success! User's Role(s) has been updated successfully",
  updateDispatchStatus: {
    [DispatchStatus.Cancel]: "Success! Dispatch Cancelled",
    [DispatchStatus.Completed]: "Success! Dispatch Completed",
  },
};
