import useText from "utils/hooks/useText";

interface IHistoryDialogHook {
  close: string;
  viewHistory: string;
  activity: string;
  duplicatedAndModified: string;
  updatedBy: string;
  memo: string;
  created: string;
  createdBy: string;
  origin: string;
}

const useTemplateHistoryDialog = (): IHistoryDialogHook => {
  const { close } = useText("buttons");
  const {
    viewHistory,
    activity,
    duplicatedAndModified,
    updatedBy,
    memo,
    created,
    createdBy,
    origin,
  } = useText("historyDialog");

  return {
    viewHistory,
    activity,
    duplicatedAndModified,
    updatedBy,
    memo,
    created,
    createdBy,
    origin,
    close,
  };
};

export default useTemplateHistoryDialog;
