import { createSlice } from "@reduxjs/toolkit";

import { resetPassword } from "./resetPassword.action";
import { RESET_PASSWORD } from "./resetPassword.model";
import {
  resetPasswordFulfilled,
  resetPasswordRejected,
} from "./resetPassword.reducers";

const initialState: RESET_PASSWORD = {
  isLoading: false,
  error: "",
  success: false,
};

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(resetPassword.pending, (state, action) => {
      state.isLoading = true;
    }),
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      resetPasswordFulfilled(state, action);
    }),
    builder.addCase(resetPassword.rejected, (state, action) => {
      resetPasswordRejected(state, action);
    })
  ),
});

export const { reset } = resetPasswordSlice.actions;
