import ErrorCard from "components/ErrorCard";
import { Component } from "react";

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorCard errorMsg="Something went wrong." />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
