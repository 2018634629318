import { useState, useRef, useEffect, useCallback } from "react";

const useUpdateDynamicHeight = (minusHeight: number = 0) => {
  const [dynamicHeight, setDynamicHeight] = useState(() => `calc(100vh)`);
  const dynamicHeightRef = useRef(null);
  const updateMaxHeight = useCallback(() => {
    if (dynamicHeightRef.current) {
      const offsetTopHeight =
        dynamicHeightRef.current.getBoundingClientRect().top;
      setDynamicHeight(`calc(100vh - ${offsetTopHeight + minusHeight}px)`);
    }
  }, [minusHeight]);

  useEffect(() => {
    updateMaxHeight();
  }, [updateMaxHeight]);

  requestAnimationFrame(updateMaxHeight);

  return {
    dynamicHeight,
    dynamicHeightRef,
  };
};

export default useUpdateDynamicHeight;
