import React, { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const DragBox = styled(Box)`
  height: 125px;
  border: 3px dashed #d3d3d3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileDropzone = ({
  onFileDrop,
  fileName = "",
  errorFile = false,
  fileList = [],
  errorFiles = [{ name: "", error: false }],
}) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = e.dataTransfer.files;

    // Handle the dropped files
    if (onFileDrop) {
      onFileDrop(files);
    }
  };

  return (
    <DragBox
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <UploadFileIcon sx={{ fontSize: "59px", opacity: 0.5 }} />
      <Typography sx={{ fontSize: "12px", color: "#5B5B5B" }}>
        {fileList.length > 0
          ? fileList.length + " files selected"
          : fileName
          ? fileName
          : "Drag to Upload"}
      </Typography>
      <VisuallyHiddenInput type="file" accept=".csv" />
      {(errorFiles.length > 0 &&
        errorFiles.map((file, index) => (
          <Typography key={index} color="error">
            {file.name}: {file.error}
          </Typography>
        ))) ||
        (errorFile && <Typography color="error">{errorFile}</Typography>)}
    </DragBox>
  );
};

export default FileDropzone;
