import { Card, CardContent, Grid, TextFieldProps } from "@mui/material";
import Button from "components/Button";
import PerformantTextField from "components/PerformantTextField";
import { gutterSpace } from "constants/styles";

type LookupCardPropType = {
  onBtnClick: () => void;
  textFieldProps: TextFieldProps;
  btnText: string;
};

const LookupCard = (props: LookupCardPropType) => {
  const { textFieldProps, btnText, onBtnClick } = props;

  return (
    <Card>
      <CardContent sx={{ padding: "14px 20px !important" }}>
        <Grid container spacing={gutterSpace}>
          <Grid item xs={5}>
            <PerformantTextField
              name={textFieldProps.name}
              {...textFieldProps}
            />
          </Grid>
          <Grid item xs={7}>
            <Button variant="contained" color="primary" onClick={onBtnClick}>
              {btnText || "Lookup"}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LookupCard;
