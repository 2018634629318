import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ERROR_MESSAGES,
  MULTIPART_HEADER,
  STATUS_CODE,
} from "../../../constants";
import { PurchasePayload } from "types/purchase";
import {
  GetByIdPayload,
  GetFilteredOrSearchedAssetsPayload,
  GetViewAssetTemplatePayload,
} from "types/inventory";
import axiosClient from "utils/axiosClient";
import {
  CREATE_PURCHASE_ORDER_URL,
  GET_PURCHASE_ORDERS_URL,
  GET_PURCHASE_ORDER_BY_ID_URL,
  GET_ASSET_TEMPLATE_HISTORY_BY_ID_URL,
  GET_PURCHASE_ORDER_HISTORY_BY_ID_URL,
  EDIT_PURCHASE_ORDER_URL,
  GET_ALL_INVENTORY,
  GET_INVENTORY_URL,
  GET_ALL_PURCHASE_ORDER_STATUSES_URL,
} from "utils/urls";
import AppError from "utils/AppError";
import { generateQueryParams } from "utils/common";
import { convertDimensions } from "containers/inventory/asset/createasset/conversion";

export const getAssetTemplates = createAsyncThunk(
  GET_ALL_INVENTORY,
  async (payload: GetFilteredOrSearchedAssetsPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        filter: payload.filter,
        field: payload.field,
        sort: payload.sort,
        isTemplate: true,
      });

      const response = await axiosClient.get(
        `${GET_ALL_INVENTORY}?${queryParams}`
      );
      return {
        records: response.data?.records,
        totalRecords: response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetTemplate,
        error.response.data?.message
      );
    }
  }
);

export const getPurchaseOrders = createAsyncThunk(
  GET_PURCHASE_ORDERS_URL,
  async (payload: GetFilteredOrSearchedAssetsPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        filter: payload.filter,
        field: payload.field,
        sort: payload.sort,
      });

      const response = await axiosClient.get(
        `${GET_PURCHASE_ORDERS_URL}?${queryParams}`
      );
      return {
        records: response.data?.records,
        totalRecords: response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const createPurchaseOrder = createAsyncThunk(
  CREATE_PURCHASE_ORDER_URL,
  async (payload: PurchasePayload) => {
    try {
      const response = await axiosClient.post(
        CREATE_PURCHASE_ORDER_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.CREATED_SUCCESS)
        return "Success! Purchase Order created";
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const editPurchaseOrder = createAsyncThunk(
  EDIT_PURCHASE_ORDER_URL,
  async (payload: PurchasePayload) => {
    try {
      const response = await axiosClient.put(
        EDIT_PURCHASE_ORDER_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.UPDATE_SUCCESS)
        return "Success! Purchase Order updated.";
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const getAssetTemplateById = createAsyncThunk(
  GET_INVENTORY_URL,
  async (payload: GetViewAssetTemplatePayload) => {
    try {
      const queryParams = generateQueryParams({
        isTemplate: true,
      });

      const response = await axiosClient.get(
        `${GET_INVENTORY_URL}?${queryParams}`,
        {
          params: payload,
        }
      );
      const { data } = response;
      const manipulatedData = convertDimensions(data);
      return manipulatedData;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetTemplateById,
        error.response.data?.message
      );
    }
  }
);

export const getPurchaseOrderById = createAsyncThunk(
  GET_PURCHASE_ORDER_BY_ID_URL,
  async (payload: GetViewAssetTemplatePayload) => {
    try {
      const response = await axiosClient.get(GET_PURCHASE_ORDER_BY_ID_URL, {
        params: payload,
      });
      const { data } = response;
      const manipulatedData = {
        ...data,
        assets: data.assets.map((asset) => convertDimensions(asset)),
      };
      return manipulatedData;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const getAssetTemplateHistoryById = createAsyncThunk(
  GET_ASSET_TEMPLATE_HISTORY_BY_ID_URL,
  async (payload: GetViewAssetTemplatePayload) => {
    try {
      const response = await axiosClient.get(
        GET_ASSET_TEMPLATE_HISTORY_BY_ID_URL,
        {
          params: payload,
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetTemplateHistoryById,
        error.response.data?.message
      );
    }
  }
);

export const getPurchaseOrderHistoryById = createAsyncThunk(
  GET_PURCHASE_ORDER_HISTORY_BY_ID_URL,
  async (payload: GetByIdPayload) => {
    try {
      const response = await axiosClient.get(
        GET_PURCHASE_ORDER_HISTORY_BY_ID_URL,
        {
          params: payload,
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPurchaseOrderHistory,
        error.response.data?.message
      );
    }
  }
);

export const getAllPurchaseOrderStatuses = createAsyncThunk(
  GET_ALL_PURCHASE_ORDER_STATUSES_URL,
  async () => {
    try {
      const response = await axiosClient.get(
        GET_ALL_PURCHASE_ORDER_STATUSES_URL
      );
      const statuses = response.data?.filter((status) => status.isActive);
      return statuses;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllPurchaseOrderStatusError,
        error.response.data?.message
      );
    }
  }
);
