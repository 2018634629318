import { Box } from "@mui/material";
import { TopIndicator } from "components";
import { pageStyle, topIndicatorBar } from "constants/styles";

const SecurityWrapper = ({ children }) => {
  return (
    <>
      <TopIndicator
        backgroundColor="appModules.400"
        sx={{ mb: topIndicatorBar.spaceBottom }}
      />
      <Box sx={pageStyle}>{children}</Box>
    </>
  );
};

export default SecurityWrapper;
