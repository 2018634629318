import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  PERSONNEL_BULK_IMPORT_URL,
  GET_COUNTRIES,
  GET_STATES_BY_COUNTRY_URL,
  GET_ALL_PERSONNEL,
  GET_PERSONNEL,
  GET_LOCATIONS,
  MERGE_PERSONNEL,
  CREATE_PERSONNEL_URL,
  EDIT_PERSONNEL_URL,
  GET_MANAGERS,
  GET_DEPARTMENTS,
  NOTIFY_USER_EMAIL,
  GET_COUNTIES,
  GET_REGIONS_URL,
  GET_STATES_BY_REGION_URL,
  GET_STATES_URL,
  GET_CURRENT_USER,
  GET_PORTERS,
} from "utils/urls";
import axiosClient from "utils/axiosClient";
import {
  PersonnelPayload,
  GetStatesPayload,
  GetViewPersonnelPayload,
  MergePersonnelPayload,
  PersonnelBulkImportPayload,
  GetDepartmentPayload,
  NotifyUserEmailPayload,
  GetCountiesPayload,
} from "types/security";

import { GetFilteredOrSearchedPersonnelsPayload } from "types";
import { searchableColumns } from "containers/security";
import {
  ERROR_MESSAGES,
  MULTIPART_HEADER,
  STATUS_CODE,
} from "../../../constants";
import AppError from "../../../utils/AppError";
import { generateQueryParams } from "utils/common";

export const getAllPersonnels = createAsyncThunk(
  GET_ALL_PERSONNEL,
  async (payload: GetFilteredOrSearchedPersonnelsPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        filter: payload.filter,
        field: payload.field,
        sort: payload.sort,
      });
      const response = await axiosClient.get(
        `${GET_ALL_PERSONNEL}?${queryParams}`
      );
      const { data } = response;
      let personnelList = data.records.map((personnel, index) => {
        return {
          id: personnel.userId,
          ...personnel,
          roleName: personnel.roleNames.join(" | "),
          description: personnel.roleDetails
            .map((desc) => {
              return desc.description;
            })
            .join(" | "),
        };
      });
      if (payload.search) {
        personnelList = personnelList.filter(
          (data) =>
            !!searchableColumns.filter((columnName) =>
              data[columnName].toLowerCase().includes(payload.search)
            ).length
        );
      }
      return {
        records: personnelList,
        totalRecords: response.data.totalRecords,
      };
      //return personnelList;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllPersonnels,
        error.response.data?.message
      );
    }
  }
);

export const getPersonnel = createAsyncThunk(
  GET_PERSONNEL,
  async (payload: GetViewPersonnelPayload) => {
    try {
      const response = await axiosClient.get(GET_PERSONNEL, {
        params: payload,
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPersonnel,
        error.response.data?.message
      );
    }
  }
);

export const getLocations = createAsyncThunk(GET_LOCATIONS, async () => {
  try {
    const response = await axiosClient.get(GET_LOCATIONS);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getLocations,
      error.response.data?.message
    );
  }
});
export const getPorters = createAsyncThunk(GET_PORTERS, async () => {
  try {
    const response = await axiosClient.get(GET_PORTERS);

    const { data } = response;

    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getPorters, error.response.data?.message);
  }
});

export const mergePersonnels = createAsyncThunk(
  MERGE_PERSONNEL,
  async (payload: MergePersonnelPayload) => {
    try {
      const response = await axiosClient.post(MERGE_PERSONNEL, payload);
      if (response.status === STATUS_CODE.SUCCESS)
        return response.data.status.message;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.mergePersonnels,
        error.response.data?.message
      );
    }
  }
);

export const uploadPersonnelBulkImportFile = createAsyncThunk(
  PERSONNEL_BULK_IMPORT_URL,
  async (payload: PersonnelBulkImportPayload) => {
    try {
      const response = await axiosClient.post(
        PERSONNEL_BULK_IMPORT_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.SUCCESS)
        return response.data.status.message;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.uploadPersonnelBulkImportFile,
        error.response.data?.message
      );
    }
  }
);

export const getCountries = createAsyncThunk(GET_COUNTRIES, async () => {
  try {
    const response = await axiosClient.get(GET_COUNTRIES);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getCountries,
      error.response.data?.message
    );
  }
});

export const getAllStates = createAsyncThunk(GET_STATES_URL, async () => {
  try {
    const response = await axiosClient.get(GET_STATES_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getStates, error.response.data?.message);
  }
});

export const getRegionsByCountry = createAsyncThunk(
  GET_REGIONS_URL,
  async (payload: GetStatesPayload) => {
    try {
      const response = await axiosClient.get(
        `${GET_REGIONS_URL}/${payload.id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getRegions,
        error.response.data?.message
      );
    }
  }
);

export const getStatesByCountry = createAsyncThunk(
  GET_STATES_BY_COUNTRY_URL,
  async (payload: GetStatesPayload) => {
    try {
      const response = await axiosClient.get(
        `${GET_STATES_BY_COUNTRY_URL}/${payload.id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getStates,
        error.response.data?.message
      );
    }
  }
);

export const getStatesByRegion = createAsyncThunk(
  GET_STATES_BY_REGION_URL,
  async (payload: GetStatesPayload) => {
    try {
      const response = await axiosClient.get(
        `${GET_STATES_BY_REGION_URL}/${payload.id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getStates,
        error.response.data?.message
      );
    }
  }
);

export const getCounties = createAsyncThunk(
  GET_COUNTIES,
  async (payload: GetCountiesPayload) => {
    try {
      const response = await axiosClient.get(`${GET_COUNTIES}/${payload.id}`);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getCounties, error.response.data);
    }
  }
);

export const getManagers = createAsyncThunk(
  GET_MANAGERS,
  async (payload: GetDepartmentPayload) => {
    try {
      const response = await axiosClient.get(`${GET_MANAGERS}/${payload}`);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getManagers,
        error.response.data?.message
      );
    }
  }
);

export const createPersonnel = createAsyncThunk(
  CREATE_PERSONNEL_URL,
  async (payload: PersonnelPayload) => {
    try {
      const response = await axiosClient.post(CREATE_PERSONNEL_URL, payload);
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createPersonnel,
        error.response.data?.message
      );
    }
  }
);

export const editPersonnel = createAsyncThunk(
  EDIT_PERSONNEL_URL,
  async (payload: PersonnelPayload) => {
    try {
      const response = await axiosClient.put(EDIT_PERSONNEL_URL, payload);
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editPersonnel,
        error.response.data?.message
      );
    }
  }
);

export const getDepartments = createAsyncThunk(GET_DEPARTMENTS, async () => {
  try {
    const response = await axiosClient.get(GET_DEPARTMENTS);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getDepartments,
      error.response.data?.message
    );
  }
});

export const notifyUserEmail = createAsyncThunk(
  NOTIFY_USER_EMAIL,
  async (payload: NotifyUserEmailPayload) => {
    try {
      const response = await axiosClient.get(NOTIFY_USER_EMAIL, {
        params: payload,
      });
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.notifyUserEmail,
        error.response.data?.message
      );
    }
  }
);

export const getCurrentUser = createAsyncThunk(GET_CURRENT_USER, async () => {
  try {
    const response = await axiosClient.get(GET_CURRENT_USER);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getCurrentUser,
      error.response.data?.message
    );
  }
});
