import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "redux/slices/auth/auth.slice";
import { resetPasswordSlice } from "redux/slices/resetPassword/resetPassword.slice";
import { authenticateCodeSlice } from "redux/slices/authenticateCode/authenticateCode.slice";
import { roleSlice } from "redux/slices/role/role.slice";
import { personnelSlice } from "redux/slices/personnel/personnel.slice";
import { timeClockSlice } from "redux/slices/timeClock/timeClock.slice";
import { assetSlice } from "redux/slices/inventory/inventory.slice";
import { dispatchSlice } from "redux/slices/dispatch/dispatch.slice";
import { purchasingSlice } from "redux/slices/purchasing/purchasing.slice";
import { dealershipSlice } from "redux/slices/dealership/dealership.slice";
import { financeSlice } from "redux/slices/finance/finance.slice";

const authSliceReducer = authSlice.reducer;
const resetPasswordSliceReducer = resetPasswordSlice.reducer;
const authenticateCodeReducer = authenticateCodeSlice.reducer;
const roleSliceReducer = roleSlice.reducer;
const personnelSliceReducer = personnelSlice.reducer;
const timeClockSliceReducer = timeClockSlice.reducer;
const assetSliceReducer = assetSlice.reducer;
const dispatchSliceReducer = dispatchSlice.reducer;
const purchasingreducer = purchasingSlice.reducer;
const dealershipReducer = dealershipSlice.reducer;
const financeReducer = financeSlice.reducer;

// The store is configured with the state and the corresponding reducers.
const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    resetPassword: resetPasswordSliceReducer,
    authenticateCode: authenticateCodeReducer,
    role: roleSliceReducer,
    personnel: personnelSliceReducer,
    timeClock: timeClockSliceReducer,
    asset: assetSliceReducer,
    dispatch: dispatchSliceReducer,
    purchasing: purchasingreducer,
    dealership: dealershipReducer,
    finance: financeReducer,
  },
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
