import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ADDRESS_TYPE_URL,
  GET_EMAIL_TYPE_URL,
  GET_PHONE_TYPE_URL,
  GET_WEB_TYPE_URL,
  GET_API_TYPE_URL,
  CREATE_DEALERSHIP_URL,
  UPDATE_DEALERSHIP_URL,
  GET_ALL_DEALERSHIP_URL,
  GET_BY_ID_DEALERSHIP_URL,
  DELETE_DEALERSHIP_URL,
  GET_TENANT_LOCATIONS,
} from "utils/urls";
import {
  UpdateDealershipPayload,
  GetDealershipByIdPayload,
  DeleteDealershipPayload,
} from "types/dealership";
import axiosClient from "utils/axiosClient";

import { ERROR_MESSAGES, STATUS_CODE } from "../../../constants";
import AppError from "../../../utils/AppError";

export const getAddressType = createAsyncThunk(
  GET_ADDRESS_TYPE_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ADDRESS_TYPE_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAddressType,
        error.response.data?.message
      );
    }
  }
);
export const getPhoneType = createAsyncThunk(GET_PHONE_TYPE_URL, async () => {
  try {
    const response = await axiosClient.get(GET_PHONE_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getPhoneType,
      error.response.data?.message
    );
  }
});
export const getEmailType = createAsyncThunk(GET_EMAIL_TYPE_URL, async () => {
  try {
    const response = await axiosClient.get(GET_EMAIL_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getEmailType,
      error.response.data?.message
    );
  }
});
export const getWebType = createAsyncThunk(GET_WEB_TYPE_URL, async () => {
  try {
    const response = await axiosClient.get(GET_WEB_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getWebType, error.response.data?.message);
  }
});

export const getAPIType = createAsyncThunk(GET_API_TYPE_URL, async () => {
  try {
    const response = await axiosClient.get(GET_API_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getAPIType, error.response.data?.message);
  }
});

export const getAllDealerships = createAsyncThunk(
  GET_ALL_DEALERSHIP_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_DEALERSHIP_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDealerships,
        error.response.data?.message
      );
    }
  }
);

export const getDealershipById = createAsyncThunk(
  GET_BY_ID_DEALERSHIP_URL,
  async (payload: GetDealershipByIdPayload) => {
    try {
      const response = await axiosClient.get(GET_BY_ID_DEALERSHIP_URL, {
        params: payload,
      });
      const { data } = response.data;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDealerships,
        error.response.data?.message
      );
    }
  }
);

export const createDealership = createAsyncThunk(
  CREATE_DEALERSHIP_URL,
  async (payload: unknown) => {
    try {
      const response = await axiosClient.post(CREATE_DEALERSHIP_URL, payload);
      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response.data.message;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createDealership,
        error.response.data?.message
      );
    }
  }
);

export const updateDealership = createAsyncThunk(
  UPDATE_DEALERSHIP_URL,
  async (payload: UpdateDealershipPayload) => {
    try {
      const response = await axiosClient.post(UPDATE_DEALERSHIP_URL, payload);
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.updateDealership,
        error.response.data?.message
      );
    }
  }
);

export const deleteDealership = createAsyncThunk(
  DELETE_DEALERSHIP_URL,
  async (payload: DeleteDealershipPayload) => {
    try {
      const response = await axiosClient.post(DELETE_DEALERSHIP_URL, payload);
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.deleteDealership,
        error.response.data?.message
      );
    }
  }
);

export const getTenantLocations = createAsyncThunk(
  GET_TENANT_LOCATIONS,
  async () => {
    try {
      const response = await axiosClient.get(GET_TENANT_LOCATIONS);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getTenantLocations,
        error.response.data?.message
      );
    }
  }
);
