// useModal.ts
import { useState } from "react";

interface ModalHook {
  isOpen: boolean;
  toggleModal: () => void;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}

const useModal = (): ModalHook => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleModal = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleFullScreen = () => {
    setIsFullScreen((prevIsFullScreen) => !prevIsFullScreen);
  };

  return { isOpen, toggleModal, isFullScreen, toggleFullScreen };
};

export default useModal;
