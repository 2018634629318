import { PaletteColorOptions, createTheme } from "@mui/material/styles";
const pxToRem = (...pxValues) =>
  pxValues.map((px) => `${px / 16}rem`).join(" ");

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    greyVariant?: PaletteColorOptions;
    greyVariantAlt: PaletteColorOptions;
    appModules?: PaletteColorOptions;
    supportingColor?: PaletteColorOptions;
    supportingColorAlt?: PaletteColorOptions;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 744,
      md: 1024,
      lg: 1366,
      xl: 1440,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "gray",
            pointerEvents: "none",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
          fontWeight: 500,
          letterSpacing: pxToRem(0.15),
          lineHeight: pxToRem(24),
          padding: pxToRem(17, 12),
          marginTop: `0 !important`,
          marginBottom: pxToRem(17),
          position: "relative",

          ".MuiAlert-icon": {
            marginRight: pxToRem(24),
          },
        },

        // standard [Start]
        standardError: {
          backgroundColor: "#FCEEEE",
          color: "#410E0B",
        },
        standardInfo: {
          backgroundColor: "#FFFBFA",
          color: "#1C479A",

          ".MuiAlert-icon": {
            color: "#3285FF",
          },
        },
        standardSuccess: {
          backgroundColor: "#E3FEEF",
          color: "#224B35",

          ".MuiAlert-icon": {
            color: "#224B35",
          },
        },
        standardWarning: {
          backgroundColor: "#FBECE2",
          color: "#6A2A00",

          ".MuiAlert-icon": {
            color: "#D35400",
          },
        },
        // standard [END]

        // filled [Start]
        filledError: {
          backgroundColor: "#B3261E",
          color: "#FFFBF9",

          ".MuiAlert-icon": {
            color: "#FFFBF9",
          },
          ".MuiLinearProgress-root": {},
        },
        filledInfo: {
          backgroundColor: "#3285FF",
          color: "#FFFBFA",

          ".MuiAlert-icon": {
            color: "#FFFBFA",
          },
        },
        filledSuccess: {
          backgroundColor: "#72FAAF",
          color: "#224B35",

          ".MuiAlert-icon": {
            color: "#224B35",
          },
        },
        filledWarning: {
          backgroundColor: "#D35400",
          color: "#FBECE2",

          ".MuiAlert-icon": {
            color: "#FBECE2",
          },
        },
        // filled [END]

        // outline [Start]
        outlinedError: {
          borderColor: "#B3261E",
          color: "#410E0B",

          ".MuiAlert-icon": {
            color: "#B3261E",
          },
        },
        outlinedInfo: {
          borderColor: "#3285FF",
          color: "#1A369A",

          ".MuiAlert-icon": {
            color: "#1A369A",
          },
        },
        outlinedSuccess: {
          borderColor: "#72FAAF",
          color: "#224B35",

          ".MuiAlert-icon": {
            color: "#224B35",
          },
        },
        outlinedWarning: {
          borderColor: "#D35400",
          color: "#6A2A00",

          ".MuiAlert-icon": {
            color: "#D35400",
          },
        },
        // outline [END]
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",

          ".MuiAccordionSummary-expandIconWrapper": {
            color: "#322F37",

            "&:not(.Mui-expanded)": {
              transform: "rotate(90deg) !important",
            },

            "&.Mui-expanded": {
              transform: "rotate(0deg) !important",
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          width: pxToRem(480),
        },
        paperWidthMd: {
          width: pxToRem(630),
        },
        paperWidthLg: {
          width: pxToRem(830),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#322F35",
          opacity: 0.7,
        },
        tooltipPlacementBottom: {
          marginTop: `${pxToRem(7)} !important`,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          width: pxToRem(205),
          padding: pxToRem(8, 0),
          boxShadow: `0 ${pxToRem(2, 6, 2)} rgba(0, 0, 0, 0.15), ${pxToRem(
            0,
            1,
            2
          )} 0 rgba(0, 0, 0, 0.30)`,

          ".MuiList-padding": {
            padding: 0,
          },
          ".MuiMenuItem-root": {
            color: "#5B5B5B",
            padding: pxToRem(12),
          },
          ".MuiSvgIcon-root": {
            fontSize: pxToRem(16),
            color: "#322F37",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollableX: {
          height: pxToRem(43),
        },
        indicator: {
          height: pxToRem(3),
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: pxToRem(43),
          fontSize: pxToRem(12),
          fontWeight: 500,
          lineHeight: pxToRem(16),
          letterSpacing: pxToRem(0.5),
          padding: pxToRem(8, 17),
          flex: "1 0 auto",

          "&, &.Mui-selected": {
            color: "#000000",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMenu-paper": {
            maxHeight: pxToRem(475),
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          ".MuiCheckbox-root, .MuiRadio-root": {
            "& ~ .MuiTypography-root": {
              fontSize: pxToRem(13.75),
              fontWeight: 600,
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "'Gantari', sans-serif",
    fontSize: 14,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    // Theme Color Set
    primary: {
      main: "#5298FF", // 400
      50: "#00255D", // M3/ref/primary/primary10  / 0
      100: "#00255D", // M3/ref/primary/primary10 / 10
      200: "#1A369A", // M3/ref/primary/primary20 / 20
      300: "#3674CE", // M3/ref/primary/primary30 / 30
      400: "#5298FF", // M3/ref/secondary/secondary50 / 40 / alias
      500: "#678ABE", // M3/ref/primary/primary50 / 50
      600: "#82A6DB", // M3/ref/primary/primary60 / 60
      700: "#9DB6F8", // M3/ref/primary/primary70 / 70
      800: "#78AFFF", // M3/ref/primary/primary80 / 80
      900: "#E7F0FF", // M3/ref/primary/primary95 / 95
      A100: "#F9F9F9", // M3/ref/primary/primary99 / 99
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#418EFF", // 400
      100: "#16387B", // M3/ref/secondary/secondary10 / 10
      200: "#1C479A", // M3/ref/secondary/secondary20 / 20
      300: "#226EDE", // M3/ref/secondary/secondary30 / 30
      400: "#418EFF", // M3/ref/secondary/secondary40 / 40 / alias
      500: "#5298FF", // M3/ref/secondary/secondary50 / 50
      600: "#64A3FF", // M3/ref/secondary/secondary60 / 60
      700: "#70AAFF", // M3/ref/secondary/secondary70 / 70
      800: "#82B4FF", // M3/ref/secondary/secondary80 / 80
      900: "#9CC4FF", // M3/ref/secondary/secondary90 / 90
      A100: "#CCE1FF", // M3/ref/secondary/secondary95 / 95
      contrastText: "#ffffff",
    },
    tertiary: {
      main: "#1243A4", // 400
      100: "#16387B", // M3/ref/tertiary/tertiary10 / 10
      200: "#1C479A", // M3/ref/tertiary/tertiary20 / 20
      300: "#1C58AF", // M3/ref/tertiary/tertiary30 / 30
      400: "#1243A4", // M3/ref/tertiary/tertiary40 / 40 / alias
      500: "#697698", // M3/ref/tertiary/tertiary50 / 50
      600: "#8394B5", // M3/ref/tertiary/tertiary60 / 60
      700: "#AAB6CD", // M3/ref/tertiary/tertiary70 / 70
      800: "#95ABD4", // M3/ref/tertiary/tertiary80 / 80
      900: "#3285FF", // M3/ref/tertiary/tertiary90 / 90
      A100: "#B8D1EF", // M3/ref/tertiary/tertiary95 / 95
      A200: "#FFFBFA", // M3/ref/tertiary/tertiary99 / 99
      contrastText: "#ffffff",
    },
    grey: {
      50: "#000000", // M3/ref/neutral/neutral0 / 0
      100: "#1D1B20", // M3/ref/neutral/neutral10 / 10
      200: "#48464C", // M3/ref/neutral/neutral30 / 20
      300: "#48464C", // M3/ref/neutral/neutral30 / 30
      400: "#605D64", // M3/ref/neutral/neutral40 / 40
      500: "#79767D", // M3/ref/neutral/neutral50 / 50
      600: "#938F96", // M3/ref/neutral/neutral60 / 60
      700: "#AEA9B1", // M3/ref/neutral/neutral70 / 70
      800: "#CAC5CD", // M3/ref/neutral/neutral80 / 80
      900: "#E0E3E9", // M3/ref/neutral/neutral90 / 90
      A100: "#EFF1F7", // M3/ref/neutral/neutral95 / 95
      A200: "#F9F9F9", // M3/ref/primary/primary99 / 99
      A400: "#FFFFFF", // M3/ref/neutral/neutral100 / 100
    },
    greyVariant: {
      // 50: '#000000', // M3/ref/neutral-variant/neutral-variant0 / 0
      100: "#1D1A22", // M3/ref/neutral-variant/neutral-variant10 / 10
      200: "#322F37", // M3/ref/neutral-variant/neutral-variant20 / 20
      300: "#49454F", // M3/ref/neutral-variant/neutral-variant30 / 30
      400: "#605D66", // M3/ref/neutral-variant/neutral-variant40 / 40
      500: "#79747E", // M3/ref/neutral-variant/neutral-variant50 / 50
      600: "#938F99", // M3/ref/neutral-variant/neutral-variant60 / 60
      700: "#AEA9B4", // M3/ref/neutral-variant/neutral-variant70 / 70
      800: "#CAC4D0", // M3/ref/neutral-variant/neutral-variant80 / 80
      900: "#E0E6EC", // M3/ref/neutral-variant/neutral-variant90 / 90
      A100: "#F7F7F7", // M3/ref/neutral-variant/neutral-variant95 / 95
      // A200: '#F9F9F9', // M3/ref/primary/primary99 / 99
      // A400: '#FFFFFF', // M3/ref/neutral-variant/neutral-variant100 / 100
      A400: "#3B383E",
    },

    greyVariantAlt: {
      50: "#D8D8E1", //Surface Dim - N-98
      100: "#F6F6F6", //Surface - N-98
      200: "#F7F8FF", //Surface Bright - N-98
      300: "##fffff", //Surf. Container Lowest
      400: "#ECECEC", //Surf. Container Low - N-96
      500: "#FEFEFE", //Surf. Container - N-94
      600: "#EFEFEF", //Surf. Container High - N-92
      700: "#5B5B5B", //onSurface - N-10
      800: "#979797", //onSurface var - NV-30
      900: "#D3D3D3", //Surface outline - NV-50
      A100: "#E2E2E2", //Surface outline variaint - NV-80
      A200: "#D1D1D1", //Surface outline variaint - NV-80
    },

    error: {
      main: "#B3261E", // M3/ref/error/error40
      100: "#410E0B", // M3/ref/error/error10 / 10
      200: "#601410", // M3/ref/error/error20 / 20
      300: "#8C1D18", // M3/ref/error/error30 / 30
      400: "#B3261E", // M3/ref/error/error40 / 40
      500: "#DC362E", // M3/ref/error/error50 / 50
      600: "#E46962", // M3/ref/error/error60 / 60
      700: "#EC928E", // M3/ref/error/error70 / 70
      800: "#F2B8B5", // M3/ref/error/error80 / 80
      900: "#F9DEDC", // M3/ref/error/error90 / 90
      A100: "#FCEEEE", // M3/ref/error/error95 / 95
      A200: "#FFFBF9", // M3/ref/error/error99 / 99
    },

    success: {
      main: "#72FAAF", //#50AF7B
      100: "#0B1912", // M3/ref/Success/10 / 10
      200: "#224B35", // M3/ref/Success/20 / 20
      300: "#397D58", // M3/ref/Success/30 / 30
      400: "#50AF7B", // M3/ref/Success/40 / 40
      500: "#72FAAF", // M3/ref/Success/50 / 50
      600: "#8FFBBF", // M3/ref/Success/60 / 60
      700: "#AAFCCF", // M3/ref/Success/70 / 70
      800: "#C6FDDF", // M3/ref/Success/80 / 80
      900: "#D4FDE7", // M3/ref/Success/90 / 90
      A100: "#E3FEEF", // M3/ref/Success/95 / 95
      A200: "#F1FEF7", // M3/ref/Success/99 / 99
      contrastText: "#000",
      dark: "red",
    },

    warning: {
      main: "#552200", // M3/ref/warning/10
      50: "#6A2A00", // M3/ref/warning/20
      100: "#D35400", // M3/ref/warning/30
      200: "#FBECE2", // M3/ref/warning/99
    },

    appModules: {
      100: "#000000", // Dealership
      200: "#205BDB", // Inventory
      300: "#0BAA53", // Finance
      400: "#FF8D12", // Personnel
      500: "#F7B500", // Reports
      600: "#9747FF", // Alerts
      700: "#00ACD1", // Clock
    },

    supportingColor: {
      50: "#0569FF",
      100: "#AF05FF",
      200: "#44D16C",
      300: "#06ADB8",
      400: "#FF9E2D",
      500: "#FFC805",
      600: "#FFD78C",
      700: "#001AFF",
      800: "#3419C6",
      900: "#6367C8",
      A100: "#1D65A6",
      A200: "#7FB806",
      A400: "#FF0541",
      A700: "#28C7A1",
    },

    supportingColorAlt: {
      50: "#0569FF",
      100: "#4D54FF",
      200: "#E76100",
      300: "#1C55E6",
      400: "#9DFCC7",
      500: "#05FFE1",
      600: "#A9E3FA",
      700: "#FF7898",
      800: "#FFBC6D",
      900: "#FF05C8",
      A100: "#4C794B",
      A200: "#4CAF50",
    },
  },
});

export default theme;
