import { useEffect, useState } from "react";
import { Box, BoxProps, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import styled from "@emotion/styled";
import PageBody from "components/PageBody";
import {
  pageBottomSpacing,
  gutterSpace,
  pxToRem,
  formRightPad,
} from "constants/styles";

interface TabMenuPropType {
  menuItemsAndContent: Array<{
    id: string;
    value: string;
    content: React.ReactNode;
  }>;
  sx?: object;
  onTabChange?: (id: string) => void;
  isFullPageTabs?: boolean;
  isFormRightPad?: boolean;
  tabValue?: string;
}

const TabsWrapper = styled(Box)<BoxProps>();

const TabMenu = (props: TabMenuPropType) => {
  const {
    menuItemsAndContent,
    sx,
    onTabChange,
    isFullPageTabs = false,
    isFormRightPad = false,
    tabValue,
  } = props;

  const [value, setValue] = useState(menuItemsAndContent[0].id);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
      if (onTabChange) {
        onTabChange(tabValue);
      }
    }
  }, [tabValue]);

  return (
    <TabsWrapper sx={sx}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {menuItemsAndContent?.map((item) => (
          <Tab
            key={item.id}
            label={item.value}
            value={item.id}
            sx={{ fontSize: pxToRem(14), fontWeight: 600 }}
          />
        ))}
      </Tabs>
      {menuItemsAndContent?.map((item) => (
        <PageBody isFullPageView={isFullPageTabs}>
          <TabPanel
            key={item.id}
            value={value}
            id={item.id}
            sx={{
              paddingBottom: isFullPageTabs && pageBottomSpacing,
              paddingRight: isFormRightPad && formRightPad,
            }}
          >
            <Box my={gutterSpace}>{item.content}</Box>
          </TabPanel>
        </PageBody>
      ))}
    </TabsWrapper>
  );
};

export default TabMenu;
