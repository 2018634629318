import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ALL_CHECK_IN_OUT_URL,
  CREATE_CHECKIN_URL,
  GET_ALL_CHECK_IN_OUT_BY_USERID_URL,
  EDIT_DND_URL,
  EDIT_CHECKOUT_URL,
  EDIT_TIMESHEET_URL,
  GET_CHECK_IN_OUT_BY_ID_URL,
  GET_IP_ADDRESS,
} from "utils/urls";
import {
  GetViewAttendanceByUserIdPayload,
  GetFilteredOrSearchedAttendancesPayload,
  GetViewAttendanceByIdPayload,
  CreateCheckInPayload,
  UpdateCheckOutPayload,
  UpdateTimeSheetPayload,
} from "types/timeclock";
import axiosClient from "utils/axiosClient";
import { ERROR_MESSAGES, STATUS_CODE } from "../../../constants";
import axios from "axios";
import AppError from "utils/AppError";

export const getAllAttendances = createAsyncThunk(
  GET_ALL_CHECK_IN_OUT_URL,
  async (payload: GetFilteredOrSearchedAttendancesPayload) => {
    try {
      const response = await axiosClient.get(GET_ALL_CHECK_IN_OUT_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAttendances,
        error.response.data?.message
      );
    }
  }
);

export const getAttendance = createAsyncThunk(
  GET_ALL_CHECK_IN_OUT_BY_USERID_URL,
  async (payload: GetViewAttendanceByUserIdPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_CHECK_IN_OUT_BY_USERID_URL,
        {
          params: payload,
        }
      );
      const { data } = response.data;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAttendances,
        error.response.data?.message
      );
    }
  }
);

export const getAttendanceById = createAsyncThunk(
  GET_CHECK_IN_OUT_BY_ID_URL,
  async (payload: GetViewAttendanceByIdPayload) => {
    try {
      const response = await axiosClient.get(GET_CHECK_IN_OUT_BY_ID_URL, {
        params: payload,
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAttendances,
        error.response.data?.message
      );
    }
  }
);

export const createCheckIn = createAsyncThunk(
  CREATE_CHECKIN_URL,
  async (payload: CreateCheckInPayload) => {
    try {
      const response = await axiosClient.post(CREATE_CHECKIN_URL, payload);
      if (response.status === STATUS_CODE.CREATED_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createCheckInTime,
        error.response.data?.message
      );
    }
  }
);

export const editCheckOut = createAsyncThunk(
  EDIT_CHECKOUT_URL,
  async (payload: UpdateCheckOutPayload) => {
    try {
      const response = await axiosClient.put(EDIT_CHECKOUT_URL, payload);
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editCheckOutTime,
        error.response.data?.message
      );
    }
  }
);

export const editTimeSheet = createAsyncThunk(
  EDIT_TIMESHEET_URL,
  async (payload: UpdateTimeSheetPayload) => {
    try {
      const response = await axiosClient.put(EDIT_TIMESHEET_URL, payload);
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editTimeSheet,
        error.response.data?.message
      );
    }
  }
);

export const editDND = createAsyncThunk(EDIT_DND_URL, async () => {
  try {
    const response = await axiosClient.put(EDIT_DND_URL);
    if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.editDND, error.response.data?.message);
  }
});

export const getIPAddress = createAsyncThunk(GET_IP_ADDRESS, async () => {
  try {
    const response = await axios.get(GET_IP_ADDRESS);
    if (response.status === STATUS_CODE.SUCCESS) return response.data.ip;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getIPAddress,
      error.response.data?.message
    );
  }
});
