import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CREATE_INVENTORY_ASSET,
  GET_ALL_FLOOR_LAYOUTS_URL,
  GET_ALL_INVENTORY,
  GET_ALL_SUB_FLOOR_PLANS_URL,
  GET_INVENTORY_URL,
  GET_ALL_TITLE_STATUS_URL,
  GET_ALL_YEARS_BY_TITLE_STATUS_URL,
  GET_ALL_ASSET_TYPES_BY_YEAR_URL,
  GET_ALL_MANUFACTURERS_BY_ASSET_TYPE_URL,
  GET_ALL_DIVISIONS_BY_MANUFACTURER_URL,
  GET_ALL_BRANDS_BY_DIVISION_URL,
  GET_ALL_SERIES_BY_BRAND_URL,
  GET_ALL_MODELS_BY_SERIES_URL,
  GET_ALL_SUB_SERIES_BY_SERIES_URL,
  GET_ALL_MODELS_BY_SUB_SERIES_URL,
  GET_ALL_MODELS_BY_BRAND_URL,
  GET_ALL_UNIT_STATUS_URL,
  GET_ALL_UNIT_TYPES_URL,
  GET_ALL_BODY_STYLES_URL,
  GET_ALL_CYLINDER_URL,
  GET_ALL_TRANSMISSION_TYPE_URL,
  GET_ALL_FUEL_TYPE_URL,
  GET_ALL_ACCESSORIES_TYPE_URL,
  GET_ALL_WARRANTY_STATUS_URL,
  INVENTORY_BULK_IMPORT_URL,
  GET_ALL_DIVISIONS_URL,
  GET_ALL_DIVISION_ALLOCATIONS_URL,
  GET_ALL_FLOOR_PLAN_STATUSES_URL,
  GET_ALL_PRODUCT_GENERAL_MANAGERS_URL,
  GET_ALL_PROFIT_CENTERS_URL,
  GET_ALL_SELLING_PRICE_CATEGORY_URL,
  GET_ALL_FLOORPLAN_COMPANY_URL,
  GET_ALL_MANUFACTURERS_URL,
  GET_ALL_LOTS_FOR_DEALERSHIP_URL,
  GET_ALL_LOT_LOCATIONS_BY_LOT_URL,
  ADD_ASSET_TEMPLATE_URL,
  CREATE_TRANSACTION_URL,
  EDIT_ASSET_URL,
  GET_ALL_TRANSPORTERS_URL,
  GET_ALL_TRANSACTIONS_URL,
  GET_TRANSACTIONS_BY_REFERENCE_NO_URL,
  UPDATE_TRANSACTION_POSTING_URL,
  GET_ASSET_HISTORY_DETAILS_URL,
  GET_ASSET_HISTORY_URL,
  GET_ASSET_BY_STOCK_URL,
  GET_ALL_ASSET_TYPES_URL,
  CREATE_DUPLICATE_ASSET,
  DOWNLOAD_ALL_TRANSACTIONS_URL,
  GET_GLACCOUNTS_URL,
} from "utils/urls";
import axiosClient from "utils/axiosClient";
import {
  AssetBulkImportPayload,
  CreateAssetPayload,
  CreateTransactionPayload,
  CreateAssetPayload as UpdateAssetPayload,
  GetAssetTypesByYearPayload,
  GetBrandsByDivisionsPayload,
  GetDivisionsByManufacturerPayload,
  GetFilteredOrSearchedAssetsPayload,
  GetLotLocationsByLotPayload,
  GetLotsByDealershipIdPayload,
  GetManufacturersByAssetTypePayload,
  GetModelsByBrandPayload,
  GetModelsBySeriesPayload,
  GetModelsBySubseriesPayload,
  GetSeriesByBrandPayload,
  GetSubseriesBySeriesPayload,
  GetTransactionsByRef,
  GetViewAssetPayload,
  GetYearsByTitleStatusPayload,
  UpdateTransactionPosting,
  GetAssetHistoryDetailsPayload,
  GetAssetByStockPayload,
  GetAssetHistoryPayload,
  CreateDuplicateAssetPayload,
} from "types/inventory";
import {
  ERROR_MESSAGES,
  JSON_HEADER,
  MULTIPART_HEADER,
  STATUS_CODE,
  inventorySuccessMessages,
} from "constants/index";
import AppError from "utils/AppError";
import { downloadFileFromResponse, generateQueryParams } from "utils/common";
import { convertDimensions } from "containers/inventory/asset/createasset/conversion";

const GET_ALL_ASSET_CREATION_SELECT_OPTIONS =
  "Inventory/createinventory/options";
const GET_ALL_UNLINKED_ITEMS = "Inventory/createinventory/unlinkedItems";

export const getAllAssets = createAsyncThunk(
  GET_ALL_INVENTORY,
  async (payload: GetFilteredOrSearchedAssetsPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        field: payload.field,
        sort: payload.sort,
        filter: payload.filter,
        ...(payload.isRoleBased ? { isRoleBased: true } : {}),
      });

      const response = await axiosClient.get(
        `${GET_ALL_INVENTORY}?${queryParams}`
      );

      return {
        records: response.data?.records,
        totalRecords: response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllAsset,
        error.response.data?.message
      );
    }
  }
);

export const createAsset = createAsyncThunk(
  CREATE_INVENTORY_ASSET,
  async (payload: CreateAssetPayload) => {
    try {
      const response = await axiosClient.post(
        CREATE_INVENTORY_ASSET,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response.data.isTemplate
          ? {
              isTemplate: response.data.isTemplate,
              message: inventorySuccessMessages.createTemplate,
            }
          : {
              isTemplate: response.data.isTemplate,
              message: inventorySuccessMessages.createAsset,
            };
      }
    } catch (error) {
      throw new AppError(
        payload.formData.get("isTemplate")
          ? ERROR_MESSAGES.createTemplate
          : ERROR_MESSAGES.createAsset,
        error.response.data?.message
      );
    }
  }
);

export const getAllTitleStatuses = createAsyncThunk(
  GET_ALL_TITLE_STATUS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_TITLE_STATUS_URL);
      const activeTitleStatuses = response.data?.filter(
        (status) => status.isActive
      );
      return activeTitleStatuses;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllTitleStatusError,
        error.response.data?.message
      );
    }
  }
);

export const getAllYearsByTitleStatus = createAsyncThunk(
  GET_ALL_YEARS_BY_TITLE_STATUS_URL,
  async (payload: GetYearsByTitleStatusPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_YEARS_BY_TITLE_STATUS_URL,
        payload
      );

      if (!response.data) {
        return [];
      }
      const activeYears = response.data.filter((year) => year.isActive);

      return activeYears;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllYearsByTitleStatusError,
        error.response.data?.message
      );
    }
  }
);

export const getAllAssetTypesByYear = createAsyncThunk(
  GET_ALL_ASSET_TYPES_BY_YEAR_URL,
  async (payload: GetAssetTypesByYearPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_ASSET_TYPES_BY_YEAR_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllAssetTypesByYearError,
        error.response.data?.message
      );
    }
  }
);
export const getAllCylinderType = createAsyncThunk(
  GET_ALL_CYLINDER_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_CYLINDER_URL);
      const activeCylinders = response.data.filter((status) => status.isActive);
      return activeCylinders;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllCylinderError,
        error.response.data?.message
      );
    }
  }
);
export const getAllFuelType = createAsyncThunk(
  GET_ALL_FUEL_TYPE_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_FUEL_TYPE_URL);
      const activeFuel = response.data.filter((status) => status.isActive);
      return activeFuel;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllFuelTypeError,
        error.response.data?.message
      );
    }
  }
);
export const getAllTransmissionType = createAsyncThunk(
  GET_ALL_TRANSMISSION_TYPE_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_TRANSMISSION_TYPE_URL);
      const activeTransmission = response.data.filter(
        (status) => status.isActive
      );
      return activeTransmission;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllTransmissionTypeError,
        error.response.data?.message
      );
    }
  }
);

export const getAllAccessoriesType = createAsyncThunk(
  GET_ALL_ACCESSORIES_TYPE_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_ACCESSORIES_TYPE_URL);

      if (!response.data) {
        return [];
      }

      let accessories = await response.data.map((accessory, index) => {
        return {
          ...accessory,
          id: accessory.accessoryId,
        };
      });

      return accessories;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllAccessoriesTypeError,
        error.response.data?.message
      );
    }
  }
);

export const getAllWarrantyStatus = createAsyncThunk(
  GET_ALL_WARRANTY_STATUS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_WARRANTY_STATUS_URL);
      const activeWarranty = response.data.filter((status) => status.isActive);
      return activeWarranty;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllWarrantyStatusError,
        error.response.data?.message
      );
    }
  }
);
export const getAllSellingPriceCategory = createAsyncThunk(
  GET_ALL_SELLING_PRICE_CATEGORY_URL,
  async () => {
    try {
      const response = await axiosClient.get(
        GET_ALL_SELLING_PRICE_CATEGORY_URL
      );
      const activeSellingPriceCategory = response.data.filter(
        (status) => status.isActive
      );
      return activeSellingPriceCategory;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllSellingPriceCategoryError,
        error.response.data?.message
      );
    }
  }
);
export const getAllFloorPlanCompany = createAsyncThunk(
  GET_ALL_FLOORPLAN_COMPANY_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_FLOORPLAN_COMPANY_URL);
      const activeFloorPlanCompany = response.data.filter(
        (status) => status.isActive
      );
      return activeFloorPlanCompany;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllFloorPlanCompanyError,
        error.response.data?.message
      );
    }
  }
);
export const getAllManufacturer = createAsyncThunk(
  GET_ALL_MANUFACTURERS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_MANUFACTURERS_URL);
      const activeManufacturer = response.data.filter(
        (status) => status.isActive
      );
      return activeManufacturer;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllManufacturersError,
        error.response.data?.message
      );
    }
  }
);

// In this action calling select options apis for floorLayouts and subFloorPlans
export const getAllUnitInfoAndPricingOptions = createAsyncThunk(
  GET_ALL_ASSET_CREATION_SELECT_OPTIONS,
  async () => {
    try {
      const floorLayoutResponse = await axiosClient.get(
        GET_ALL_FLOOR_LAYOUTS_URL
      );

      const subFloorPlanResponse = await axiosClient.get(
        GET_ALL_SUB_FLOOR_PLANS_URL
      );

      return [floorLayoutResponse.data || [], subFloorPlanResponse.data || []];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllUnitInfoAndPricingOptionsError,
        error.response.data?.message
      );
    }
  }
);

export const getAllManufacturersByAssetTypeId = createAsyncThunk(
  GET_ALL_MANUFACTURERS_BY_ASSET_TYPE_URL,
  async (payload: GetManufacturersByAssetTypePayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_MANUFACTURERS_BY_ASSET_TYPE_URL,
        payload
      );

      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllManufacturersByAssetTypeError,
        error.response.data?.message
      );
    }
  }
);

export const getAllDivisionsByManufacturerId = createAsyncThunk(
  GET_ALL_DIVISIONS_BY_MANUFACTURER_URL,
  async (payload: GetDivisionsByManufacturerPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_DIVISIONS_BY_MANUFACTURER_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDivisionsByManufacturerError,
        error.response.data?.message
      );
    }
  }
);

export const getAllBrandsByDivisionId = createAsyncThunk(
  GET_ALL_BRANDS_BY_DIVISION_URL,
  async (payload: GetBrandsByDivisionsPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_BRANDS_BY_DIVISION_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllBrandsByDivisionError,
        error.response.data?.message
      );
    }
  }
);

export const getAllSeriesByBrandId = createAsyncThunk(
  GET_ALL_SERIES_BY_BRAND_URL,
  async (payload: GetSeriesByBrandPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_SERIES_BY_BRAND_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllSeriesByBrandError,
        error.response.data?.message
      );
    }
  }
);

export const getAllModelsBySeriesId = createAsyncThunk(
  GET_ALL_MODELS_BY_SERIES_URL,
  async (payload: GetModelsBySeriesPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_MODELS_BY_SERIES_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllModelsBySeriesError,
        error.response.data?.message
      );
    }
  }
);

export const getAllSubseriesBySeriesId = createAsyncThunk(
  GET_ALL_SUB_SERIES_BY_SERIES_URL,
  async (payload: GetSubseriesBySeriesPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_SUB_SERIES_BY_SERIES_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllSubSeriesBySeriesError,
        error.response.data?.message
      );
    }
  }
);

export const getAllModelsBySubseriesId = createAsyncThunk(
  GET_ALL_MODELS_BY_SUB_SERIES_URL,
  async (payload: GetModelsBySubseriesPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_MODELS_BY_SUB_SERIES_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllModelsBySubSeriesError,
        error.response.data?.message
      );
    }
  }
);

export const getAllModelsByBrandId = createAsyncThunk(
  GET_ALL_MODELS_BY_BRAND_URL,
  async (payload: GetModelsByBrandPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_MODELS_BY_BRAND_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllModelsByBrandError,
        error.response.data?.message
      );
    }
  }
);

export const getAssetHistoryDetailsByAssetId = createAsyncThunk(
  GET_ASSET_HISTORY_DETAILS_URL,
  async (payload: GetAssetHistoryDetailsPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ASSET_HISTORY_DETAILS_URL,
        payload
      );

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetHistoryDetailsError,
        error.response.data?.message
      );
    }
  }
);
export const getAssetHistoryByAssetId = createAsyncThunk(
  GET_ASSET_HISTORY_URL,
  async (payload: GetAssetHistoryPayload) => {
    try {
      const response = await axiosClient.get(GET_ASSET_HISTORY_URL, payload);

      return response.data || [];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetHistoryError,
        error.response.data?.message
      );
    }
  }
);

export const getAllUnitStatuses = createAsyncThunk(
  GET_ALL_UNIT_STATUS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_UNIT_STATUS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllUnitStatusError,
        error.response.data?.message
      );
    }
  }
);

export const getAllUnitTypes = createAsyncThunk(
  GET_ALL_UNIT_TYPES_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_UNIT_TYPES_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllUnitTypesError,
        error.response.data?.message
      );
    }
  }
);

export const getAllBodyStyles = createAsyncThunk(
  GET_ALL_BODY_STYLES_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_BODY_STYLES_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllBodyStylesError,
        error.response.data?.message
      );
    }
  }
);

export const getAsset = createAsyncThunk(
  GET_INVENTORY_URL,
  async (payload: GetViewAssetPayload) => {
    try {
      const response = await axiosClient.get(GET_INVENTORY_URL, {
        params: payload,
      });
      const { data } = response;
      const manipulatedData = convertDimensions(data);
      return manipulatedData;
    } catch (error) {
      if (error.response) {
        throw new AppError(
          ERROR_MESSAGES.getAsset,
          error.response.data?.message
        );
      }
    }
  }
);

export const uploadAssetBulkImportFile = createAsyncThunk(
  INVENTORY_BULK_IMPORT_URL,
  async (payload: AssetBulkImportPayload) => {
    try {
      const response = await axiosClient.post(
        INVENTORY_BULK_IMPORT_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.SUCCESS)
        return response.data.status.message;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.uploadAssetBulkImportFile,
        error.response.data?.message
      );
    }
  }
);
export const getAllProductGeneralManagers = createAsyncThunk(
  GET_ALL_PRODUCT_GENERAL_MANAGERS_URL,
  async () => {
    try {
      const response = await axiosClient.get(
        GET_ALL_PRODUCT_GENERAL_MANAGERS_URL
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllProductGeneralManagers,
        error.response.data?.message
      );
    }
  }
);

export const getAllDivisions = createAsyncThunk(
  GET_ALL_DIVISIONS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_DIVISIONS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDivisionsError,
        error.response.data?.message
      );
    }
  }
);

export const getAllFloorPlanStatuses = createAsyncThunk(
  GET_ALL_FLOOR_PLAN_STATUSES_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_FLOOR_PLAN_STATUSES_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllFloorPlanStatusesError,
        error.response.data?.message
      );
    }
  }
);

export const getAllDivisionAllocations = createAsyncThunk(
  GET_ALL_DIVISION_ALLOCATIONS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_DIVISION_ALLOCATIONS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDivisionAllocationsError,
        error.response.data?.message
      );
    }
  }
);

export const getAllProfitCenters = createAsyncThunk(
  GET_ALL_PROFIT_CENTERS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_PROFIT_CENTERS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllProfitCentersError,
        error.response.data?.message
      );
    }
  }
);

export const getAllLotsByDealershipId = createAsyncThunk(
  GET_ALL_LOTS_FOR_DEALERSHIP_URL,
  async (payload: GetLotsByDealershipIdPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_LOTS_FOR_DEALERSHIP_URL,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllLotLocationsForDealershipError,
        error.response.data?.message
      );
    }
  }
);

export const getAllLotLocationsByLotId = createAsyncThunk(
  GET_ALL_LOT_LOCATIONS_BY_LOT_URL,
  async (payload: GetLotLocationsByLotPayload) => {
    try {
      const response = await axiosClient.get(
        GET_ALL_LOT_LOCATIONS_BY_LOT_URL,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllLotBuildingsByLotLocationError,
        error.response.data?.message
      );
    }
  }
);

export const addAssetTemplate = createAsyncThunk(
  ADD_ASSET_TEMPLATE_URL,
  async (payload: CreateAssetPayload) => {
    try {
      const response = await axiosClient.post(
        ADD_ASSET_TEMPLATE_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return inventorySuccessMessages.createAsset;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.addAssetTemplateError,
        error.response.data?.message
      );
    }
  }
);

export const editAsset = createAsyncThunk(
  EDIT_ASSET_URL,
  async (payload: UpdateAssetPayload) => {
    try {
      const response = await axiosClient.put(
        EDIT_ASSET_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      const { data } = response;
      return data || inventorySuccessMessages.editAsset;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editAssetError,
        error.response.data?.message
      );
    }
  }
);

export const getAllTransporters = createAsyncThunk(
  GET_ALL_TRANSPORTERS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_TRANSPORTERS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllTransportersError,
        error.response.data?.message
      );
    }
  }
);
export const createAccountingTransaction = createAsyncThunk(
  CREATE_TRANSACTION_URL,
  async (payload: CreateTransactionPayload) => {
    try {
      const response = await axiosClient.post(CREATE_TRANSACTION_URL, payload);
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createTransactionError,
        error.response.data?.message
      );
    }
  }
);
export const getAllUnlinkedItems = createAsyncThunk(
  GET_ALL_UNLINKED_ITEMS,
  async () => {
    try {
      const bodyStyles = await axiosClient.get(GET_ALL_BODY_STYLES_URL);

      const profitCenters = await axiosClient.get(GET_ALL_PROFIT_CENTERS_URL);

      const titleStatuses = await axiosClient.get(GET_ALL_TITLE_STATUS_URL);

      const unitStatuses = await axiosClient.get(GET_ALL_UNIT_STATUS_URL);

      const unitTypes = await axiosClient.get(GET_ALL_UNIT_TYPES_URL);

      const assetTypes = await axiosClient.get(GET_ALL_ASSET_TYPES_URL);

      const manufacturers = await axiosClient.get(GET_ALL_MANUFACTURERS_URL);

      return [
        bodyStyles.data || [],
        profitCenters.data || [],
        titleStatuses.data || [],
        unitStatuses.data || [],
        unitTypes.data || [],
        assetTypes.data || [],
        manufacturers.data || [],
      ];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllUnlinkedItemsError,
        error.response.data?.message
      );
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  GET_ALL_TRANSACTIONS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_ALL_TRANSACTIONS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllTransactionsError,
        error.response.data?.message
      );
    }
  }
);
export const getAllGLAccounts = createAsyncThunk(
  GET_GLACCOUNTS_URL,
  async () => {
    try {
      const response = await axiosClient.get(GET_GLACCOUNTS_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllTransactionsError,
        error.response.data?.message
      );
    }
  }
);
export const downloadAllTransactions = createAsyncThunk(
  DOWNLOAD_ALL_TRANSACTIONS_URL,
  async () => {
    try {
      const response = await axiosClient.get(DOWNLOAD_ALL_TRANSACTIONS_URL, {
        responseType: "blob",
      });

      await downloadFileFromResponse(response);

      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllTransactionsError,
        error.response.data?.message
      );
    }
  }
);

export const getTransactionsByReferenceNo = createAsyncThunk(
  GET_TRANSACTIONS_BY_REFERENCE_NO_URL,
  async (payload: GetTransactionsByRef) => {
    try {
      const response = await axiosClient.get(
        GET_TRANSACTIONS_BY_REFERENCE_NO_URL,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getTransactionsByReferenceNoError,
        error.response.data?.message
      );
    }
  }
);

export const updateTransactionPosting = createAsyncThunk(
  UPDATE_TRANSACTION_POSTING_URL,
  async (payload: UpdateTransactionPosting) => {
    try {
      const response = await axiosClient.put(
        UPDATE_TRANSACTION_POSTING_URL,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.updateTransactionPostingError,
        error.response.data?.message
      );
    }
  }
);

export const getAssetByStockNumber = createAsyncThunk(
  GET_ASSET_BY_STOCK_URL,
  async (payload: GetAssetByStockPayload) => {
    try {
      const response = await axiosClient.get(GET_ASSET_BY_STOCK_URL, {
        params: payload,
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetByStockNumberError,
        error.response.data?.message
      );
    }
  }
);

export const createDuplicateAsset = createAsyncThunk(
  CREATE_DUPLICATE_ASSET,
  async (payload: CreateDuplicateAssetPayload) => {
    try {
      const response = await axiosClient.post(
        CREATE_DUPLICATE_ASSET,
        payload,
        JSON_HEADER
      );
      if (response.status === STATUS_CODE.SUCCESS) {
        return inventorySuccessMessages.createAsset;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createAsset,
        error.response.data?.message
      );
    }
  }
);
