class AppError extends Error {
  public readonly name: string;
  public readonly message: string;
  public readonly timestamp: Date;

  constructor(name: string, message: string) {
    super(message);
    this.name = name;
    this.message = message;
    this.timestamp = new Date();
    // Ensure stack trace is captured for debugging (if supported by environment)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppError);
    }
  }
}

export default AppError;
