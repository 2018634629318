import MButton, { ButtonProps } from "@mui/material/Button";
import { containedButtonBlue } from "constants/styles";

const Button = (props: ButtonProps) => {
  return (
    <MButton
      variant="contained"
      sx={{
        ...containedButtonBlue,
        ...((!props.color || props.color === "primary") &&
          containedButtonBlue["&.MuiButtonBase-root"].primary),
        ...props.sx,
      }}
      {...props}
    >
      {props.children}
    </MButton>
  );
};

export default Button;
