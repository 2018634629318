export const rolesFulfilled = (state, action) => {
  state.isLoading = false;
  state.roles = action.payload.records;
  state.isRoleListLoading = false;
  state.totalRecords = action.payload.totalRecords;
};

export const rolesRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const roleFulfilled = (state, action) => {
  state.isLoading = false;
  state.role = action.payload;
};

export const roleCreationFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = action.payload;
};

export const roleUpdationFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = action.payload;
};

export const permissionsFulfilled = (state, action) => {
  state.isLoading = false;
  state.permissions = action.payload;
};

export const assignRoleFulfilled = (state, action) => {
  state.successMessage = action.payload;
  state.isLoading = false;
};
