import { useLocation } from "react-router-dom";

const useSubmenu = () => {
  const location = useLocation();
  const checkIsActiveItem = (item) => {
    if (item.label && item.link === location.pathname) {
      return true;
    }
  };
  return checkIsActiveItem;
};

export default useSubmenu;
