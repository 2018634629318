import { Box, Checkbox, Stack, Tooltip, Typography } from "@mui/material";
import { daysSelectorStyle, pxToRem } from "constants/styles";
import { capitalizeFirstLetter } from "utils/common";
import useDaySelector from "./useDaySelector";
import { DaySelectorProps } from "types/daySelector";

const DaySelector = ({ checkedDays = [], onSelect }: DaySelectorProps) => {
  const { days, selectedDay, handleDayClick } = useDaySelector({
    checkedDays,
    onSelect,
  });

  return (
    <Stack direction={"row"} columnGap={pxToRem(7)} sx={daysSelectorStyle}>
      {days.map((item, index) => (
        <Tooltip title={capitalizeFirstLetter(item)}>
          <Box
            key={index}
            sx={{
              ...daysSelectorStyle.dayWrapper,
              ...(selectedDay.includes(item) === true &&
                daysSelectorStyle.dayWrapper.selected),
            }}
          >
            <Typography>{item?.[0]?.toUpperCase()}</Typography>
            <Checkbox
              inputProps={{
                "aria-label": item,
              }}
              value={item}
              onChange={handleDayClick}
              checked={selectedDay.includes(item) ? true : false}
            />
          </Box>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default DaySelector;
