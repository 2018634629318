import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MENU_ITEMS as menu } from "constants/routes";

const useSidebar = () => {
  const [open, setOpen] = useState(true);
  const [subNavItems, setSubNavItems] = useState([]);
  const location = useLocation();

  const drawerToggleHandler = () => {
    setOpen((previousState) => !previousState);
  };

  const handleSideBarChange = (index: number) => {
    if (menu.length) {
      setSubNavItems(menu[index]?.childLInks);
    }
  };

  useEffect(() => {
    if (menu.length) {
      const activeIndex = menu.findIndex(
        (item) => item.link === location.pathname
      );
      if (activeIndex !== -1) {
        setSubNavItems(menu[activeIndex]?.childLInks);
      } else {
        const parentItem = menu.findIndex((item) =>
          item.childLInks
            .map((childLink) => childLink.link)
            .find((link) => link === location.pathname)
        );
        setSubNavItems(menu[parentItem]?.childLInks);
      }
    }
  }, [location.pathname]);

  return { open, subNavItems, drawerToggleHandler, handleSideBarChange };
};

export default useSidebar;
