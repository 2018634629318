export const attendancesFulfilled = (state, action) => {
  state.isLoading = false;
  state.attendances = action.payload;
  state.isRoleListLoading = false;
};

export const attendancesRejected = (state, action) => {
  const error = JSON.parse(action.error.message);
  const errorMessages = Object.values(error.errors)
    .map((errorItem) => errorItem)
    .join(",");
  state.isLoading = false;
  state.error.title = error.title;
  state.error.description = errorMessages;
};

export const attendanceFulfilled = (state, action) => {
  state.isLoading = false;
  state.attendances = action.payload;
};

export const createCheckInFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.CheckinData = action.payload;
};
export const allCheckInOutRejected = (state, action) => {
  const error = JSON.parse(action.error.message);
  const errorMessages = Object.values(error.errors)
    .map((errorItem) => errorItem)
    .join(",");
  state.isLoading = false;
  state.error.title = error.title;
  state.error.description = errorMessages;
};
export const editCheckOutFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.CheckoutData = action.payload;
};
export const editTimeSheetFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.EditTimeSheetData = action.payload;
};
export const editDNDFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.EditDNDData = action.payload;
};
export const getIPAddressFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.IPAddress = action.payload;
};
