import { useEffect, useState } from "react";

const useSummaryAccordian = (expanded: boolean) => {
  const [expand, setExpand] = useState(expanded);

  useEffect(() => {
    setExpand(expanded);
  }, [expanded, setExpand]);

  return {
    expand,
    setExpand,
  };
};

export default useSummaryAccordian;
