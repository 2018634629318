import React from "react";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { AvatarWrapper, Button } from "components";
import { pxToRem } from "constants/styles";

import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import useLogout from "utils/hooks/useLogout";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { handleLogout } = useLogout();

  return (
    <>
      <Button
        variant="text"
        disableRipple
        disableFocusRipple
        sx={{
          p: pxToRem(7),
          display: "flex",
          gap: pxToRem(7),
        }}
        onClick={handleClick}
      >
        <AvatarWrapper profileIcon badgeStatus="online" avatarSize="small" />
        <ArrowDropDownSharpIcon sx={{ color: "grey.50" }} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutSharpIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
