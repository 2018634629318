import useText from "utils/hooks/useText";

const useAlertDialogBox = (props) => {
  const {
    open,
    onClose,
    size,
    onSubmit,
    dialogTitle,
    dialogHeading,
    dialogDescription,
  } = props;
  const { cancel, continue: continueBtn } = useText("buttons");

  const handleClose = () => {
    onClose(open);
  };

  return {
    open,
    handleClose,
    size,
    onSubmit,
    dialogTitle,
    dialogHeading,
    dialogDescription,
    onClose,
    cancel,
    continueBtn,
  };
};

export default useAlertDialogBox;
