import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ERROR_MESSAGES } from "../../../constants";
import { ResetPasswordPayload } from "types/auth";
import AppError from "utils/AppError";
import axiosClient from "utils/axiosClient";
import { FORGOT_PASSWORD_URL } from "utils/urls";

export const resetPassword = createAsyncThunk(
  FORGOT_PASSWORD_URL,
  async (payload: ResetPasswordPayload) => {
    try {
      const response = await axiosClient.post(FORGOT_PASSWORD_URL, payload);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const resetPasswordSuccessState = createAction("RESET");
