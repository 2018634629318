import "styles/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import theme from "./utils/theme";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import Routes from "Routes";
import { LicenseInfo } from "@mui/x-license-pro";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_KEY);
const App = ({ instance }): JSX.Element => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  </GoogleOAuthProvider>
);

export default App;
