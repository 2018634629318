import {
  Typography,
  Stack,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { basicAccordance, pxToRem } from "constants/styles";
import { useEffect, useState } from "react";

export type CustomAccordionPropType = {
  bgColor?: string;
  mainTitle?: string;
  subTitle?: string;
  mainDescription?: React.ReactNode;
  children?: React.ReactNode;
  showCheckBox?: boolean;
  showRadio?: boolean;
  checkBoxIsChecked?: boolean;
  sx?: object;
  defaultStyle?: boolean;
  expanded?: boolean;
  preventCheckboxClick?: boolean;
  onChange?: (isChecked: boolean) => void;
};

const BasicAccordion = (props: CustomAccordionPropType) => {
  const {
    mainTitle,
    subTitle,
    mainDescription,
    showCheckBox = false,
    showRadio = false,
    sx,
    children,
    expanded = false,
    preventCheckboxClick,
    checkBoxIsChecked,
    onChange,
  } = props;

  const [expand, setExpand] = useState(expanded);
  const handleToggle = () => {
    setExpand((prev) => !prev);
  };
  useEffect(() => {
    setExpand(expanded);
  }, [expanded, setExpand]);

  return (
    <Accordion
      expanded={showCheckBox || showRadio ? expand : undefined}
      sx={{ ...basicAccordance, ...sx }}
    >
      <AccordionSummary
        expandIcon={
          showCheckBox || showRadio ? (
            <IconButton
              aria-label="toggle"
              onClick={(e) => {
                e.stopPropagation();
                handleToggle();
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          ) : (
            <IconButton>
              <ExpandMoreIcon />
            </IconButton>
          )
        }
        onClick={(e) => {
          if (preventCheckboxClick) {
            e.preventDefault();
          }
        }}
      >
        {(mainTitle || subTitle || showCheckBox) && (
          <Stack flexDirection="row" alignItems={"center"}>
            {showCheckBox && !showRadio && (
              <Checkbox
                checked={checkBoxIsChecked}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
            {showRadio && !showCheckBox && (
              <Radio
                checked={checkBoxIsChecked}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
            <Stack>
              {mainTitle && (
                <Typography
                  component="div"
                  fontSize={pxToRem(14)}
                  fontWeight={400}
                  lineHeight="normal"
                  color="grey.50"
                  letterSpacing={pxToRem(0.2)}
                >
                  {mainTitle}
                </Typography>
              )}
              {subTitle && (
                <Typography
                  component="div"
                  fontSize="10px"
                  fontWeight={300}
                  letterSpacing={pxToRem(0.2)}
                  color="grey.50"
                >
                  {subTitle}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="div"> {mainDescription || children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default BasicAccordion;
