import { createSlice } from "@reduxjs/toolkit";
import {
  createRole,
  getAllPermissions,
  getAllRoles,
  getRole,
  editRole,
  assignRole,
} from "./role.action";
import {
  assignRoleFulfilled,
  permissionsFulfilled,
  roleCreationFulfilled,
  roleFulfilled,
  roleUpdationFulfilled,
  rolesFulfilled,
  rolesRejected,
} from "./role.reducer";
import { RoleState } from "./role.model";

const initialState: RoleState = {
  isLoading: false,
  error: { title: "", description: "" },
  roles: [],
  selectedRoles: [],
  roleSummary: [],
  role: null,
  successMessage: "",
  permissions: null,
  isRoleListLoading: false,
  totalRecords: null,
};

export const roleSlice = createSlice({
  name: "role",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setRoleSummary: (state, action) => {
      state.roleSummary = action.payload;
    },
    setSelectedRoles: (state, action) => {
      state.selectedRoles = action.payload;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    resetSuccess: (state) => {
      state.successMessage = initialState.successMessage;
    },
  },
  extraReducers: (builder) => (
    builder.addCase(getAllRoles.pending, (state, action) => {
      state.isLoading = true;
      state.successMessage = "";
      state.isRoleListLoading = true;
      state.error.description = "";
      state.error.title = "";
    }),
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      rolesFulfilled(state, action);
    }),
    builder.addCase(getAllRoles.rejected, (state, action) => {
      rolesRejected(state, action);
    }),
    builder.addCase(createRole.pending, (state, action) => {
      state.isLoading = true;
      state.successMessage = "";
      state.error.description = "";
      state.error.title = "";
    }),
    builder.addCase(createRole.fulfilled, (state, action) => {
      roleCreationFulfilled(state, action);
    }),
    builder.addCase(createRole.rejected, (state, action) => {
      rolesRejected(state, action);
    }),
    builder.addCase(getAllPermissions.fulfilled, (state, action) => {
      permissionsFulfilled(state, action);
    }),
    builder.addCase(getAllPermissions.rejected, (state, action) => {
      rolesRejected(state, action);
    }),
    builder.addCase(getAllPermissions.pending, (state, action) => {
      state.isLoading = true;
      state.successMessage = "";
      state.error.description = "";
      state.error.title = "";
    }),
    builder.addCase(editRole.pending, (state, action) => {
      state.isLoading = true;
      state.error.description = "";
      state.error.title = "";
      state.successMessage = "";
    }),
    builder.addCase(editRole.fulfilled, (state, action) => {
      roleUpdationFulfilled(state, action);
    }),
    builder.addCase(editRole.rejected, (state, action) => {
      rolesRejected(state, action);
    }),
    builder.addCase(getRole.pending, (state, action) => {
      state.isLoading = true;
      state.successMessage = "";
      state.error.description = "";
      state.error.title = "";
    }),
    builder.addCase(getRole.fulfilled, (state, action) => {
      roleFulfilled(state, action);
    }),
    builder.addCase(getRole.rejected, (state, action) => {
      rolesRejected(state, action);
    }),
    builder.addCase(assignRole.pending, (state, action) => {
      state.isLoading = true;
      state.successMessage = "";
      state.error.description = "";
      state.error.title = "";
    }),
    builder.addCase(assignRole.fulfilled, (state, action) => {
      assignRoleFulfilled(state, action);
    }),
    builder.addCase(assignRole.rejected, (state, action) => {
      rolesRejected(state, action);
    })
  ),
});

export const {
  setRoleSummary,
  reset,
  setSelectedRoles,
  resetError,
  resetSuccess,
} = roleSlice.actions;
