import { Drawer, Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import dashboardIcon from "../../images/nav-icons/dashboard.svg";
import { SidebarList } from "./SidebarList";
import { drawerWidth, pxToRem } from "constants/styles";
import Submenu from "./Submenu";
import { MENU_ITEMS as menu } from "constants/routes";
import useSidebar from "./useSidebar";

const SidebarLayout = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey["A400"],
  flexGrow: "1",
}));

const SidebarIconBar = styled(Box)(({ theme }) => ({
  width: drawerWidth.default,
  backgroundColor: theme.palette.greyVariantAlt[600],
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
}));

const Sidebar = () => {
  const { open, subNavItems, drawerToggleHandler, handleSideBarChange } =
    useSidebar();

  return (
    <>
      {/* Main SideBar [Start] */}
      <Drawer
        sx={{
          width:
            open && subNavItems.length > 0
              ? drawerWidth.open
              : drawerWidth.default,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width:
              open && subNavItems.length > 0
                ? drawerWidth.open
                : drawerWidth.default,
            boxSizing: "border-box",
            flexDirection: "row",
            borderRight: "none",
          },
        }}
        variant="permanent"
        anchor="left"
        aria-label="sidebar"
      >
        {/* Sidebar Icon Bar [Start] */}
        <SidebarIconBar>
          {/* hum burger */}
          <Stack
            sx={{
              maxHeight: "102px",
              padding: "17px 0",
              borderBottomWidth: "2px",
              borderBottomStyle: "solid",
              borderBottomColor: "grey.A400",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Stack
              className="cursor-pointer"
              sx={{
                width: "42px",
                height: "42px",
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center",
                transition: "background-color 0.3s ease-in-out",
                //hover
                "&:hover": {
                  backgroundColor: "grey.900",
                },
              }}
              onClick={drawerToggleHandler}
            >
              <MenuIcon />
            </Stack>
          </Stack>

          {/* Icon Crate */}
          <SidebarList menu={menu} handleSideBarChange={handleSideBarChange} />

          {/* dashboard */}
          <Stack
            sx={{
              maxHeight: "102px",
              padding: "17px 0",
              borderTopWidth: "2px",
              borderTopStyle: "solid",
              borderTopColor: "grey.A400",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <img src={dashboardIcon} alt="" />
          </Stack>
        </SidebarIconBar>
        {/* Sidebar Icon Bar [END] */}

        {/* Sub Tab Bar [Start]  */}
        {open && subNavItems.length > 0 && (
          <SidebarLayout>
            {/* Logo Holder */}
            <Stack
              sx={{
                paddingTop: pxToRem(50),
                flex: "1 1 auto",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Logo /> */}
              <Box
                sx={{
                  width: "131px",
                  height: "43px",
                  bgcolor: "#E1E1E1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "3px",
                }}
              >
                Logo
              </Box>
            </Stack>

            {/* Sub Tabs List holder*/}
            <Submenu subNavItems={subNavItems} />
          </SidebarLayout>
        )}

        {/* Sub Tab Bar [END]  */}
      </Drawer>
      {/* Main SideBar [END] */}
    </>
  );
};

export default Sidebar;
