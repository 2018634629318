export const authFulfilled = (state, action) => {
  state.isLoading = false;
  state.token = action.payload;
};

export const authRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const refreshAuthFulfilled = (state, action) => {
  state.isLoading = false;
  state.token = action.payload.token;
  state.refreshToken = action.payload.refreshToken;
};
