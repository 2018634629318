import { Ref, useEffect, useImperativeHandle, useRef, useState } from "react";

const useSearchBar = (ref: Ref<unknown>) => {
  const [open, setOpen] = useState(true);
  const [inputWidth, setInputWidth] = useState(0);

  const anchorRef = useRef<HTMLElement>(null);

  // INFO: Set the width of the Popper to match the TextField's width
  useEffect(() => {
    if (anchorRef.current) {
      setInputWidth(anchorRef.current.offsetWidth);
    }
  }, []);

  // INFO: Handle clicking outside the popper to close it
  const handleClickAway = () => {
    closeSearchResults();
  };

  const closeSearchResults = () => {
    setOpen(false);
  };

  const openSearchResults = () => {
    setOpen(true);
  };

  // INFO: Use useImperativeHandle to expose methods/properties to the parent component
  useImperativeHandle(ref, () => ({
    openSearchResults,
    closeSearchResults,
  }));

  return { anchorRef, open, setOpen, inputWidth, handleClickAway };
};

export default useSearchBar;
