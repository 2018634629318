import { Card, FormGroup, Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface AuthCardPropsType {
  heading: string;
  details?: string | ReactNode;
  children?: ReactNode;
  footerLinks?: ReactNode;
}

const AuthCard = (props: AuthCardPropsType) => {
  const { heading, children = null, footerLinks = null, details } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        p: "36px 17px",
        boxShadow:
          "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
      }}
    >
      <FormGroup>
        <Box sx={{ mb: "26px" }}>
          <Box sx={details && { mb: "14px" }}>
            <Typography variant="h5" component="div">
              {heading}
            </Typography>
          </Box>
          {details && (
            <Box>
              <Typography variant="body1" component="div">
                {details}
              </Typography>
            </Box>
          )}
        </Box>
        {children}
        {footerLinks}
      </FormGroup>
    </Card>
  );
};

export default AuthCard;
