import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAttendances,
  getAttendance,
  createCheckIn,
  editCheckOut,
  editTimeSheet,
  editDND,
  getIPAddress,
} from "./timeClock.action";
import {
  attendanceFulfilled,
  attendancesFulfilled,
  attendancesRejected,
  createCheckInFulfilled,
  editCheckOutFulfilled,
  editTimeSheetFulfilled,
  editDNDFulfilled,
  allCheckInOutRejected,
  getIPAddressFulfilled,
} from "./timeClock.reducer";
import { AttendanceState } from "./timeClock.model";

const initialState: AttendanceState = {
  isLoading: false,
  error: { title: "", description: "" },
  attendances: [],
  selectedAttendances: [],
  attendance: null,
  isSuccess: false,
  isAttendanceListLoading: false,
  CheckinData: {
    checkinDateTime: "09:00 AM",
    checkinDeviceName: "",
    checkinIpAddress: "",
  },
  CheckoutData: {
    checkoutDateTime: "00:00 PM",
    checkoutDeviceName: "",
    checkoutIpAddress: "",
  },
  EditTimeSheetData: {
    logId: 0,
    checkinDateTime: "",
    checkoutDateTime: "",
    reason: "",
  },
  IPAddress: null,
};

export const timeClockSlice = createSlice({
  name: "timeClock",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setAttendanceSummary: (state, action) => {},
    setSelectedAttendance: (state, action) => {
      state.selectedAttendances = action.payload;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
  },
  extraReducers: (builder) => (
    builder.addCase(getAllAttendances.pending, (state, action) => {
      state.selectedAttendances = [];
      state.isLoading = true;
      state.isSuccess = false;
      state.isAttendanceListLoading = true;
    }),
    builder.addCase(getAllAttendances.fulfilled, (state, action) => {
      attendancesFulfilled(state, action);
    }),
    builder.addCase(getAllAttendances.rejected, (state, action) => {
      attendancesRejected(state, action);
    }),
    builder.addCase(getAttendance.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    }),
    builder.addCase(getAttendance.fulfilled, (state, action) => {
      attendanceFulfilled(state, action);
    }),
    builder.addCase(getAttendance.rejected, (state, action) => {
      attendancesRejected(state, action);
    }),
    builder.addCase(createCheckIn.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    }),
    builder.addCase(createCheckIn.fulfilled, (state, action) => {
      createCheckInFulfilled(state, action);
    }),
    builder.addCase(createCheckIn.rejected, (state, action) => {
      allCheckInOutRejected(state, action);
    }),
    builder.addCase(editCheckOut.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    }),
    builder.addCase(editCheckOut.fulfilled, (state, action) => {
      editCheckOutFulfilled(state, action);
    }),
    builder.addCase(editCheckOut.rejected, (state, action) => {
      allCheckInOutRejected(state, action);
    }),
    builder.addCase(editTimeSheet.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    }),
    builder.addCase(editTimeSheet.fulfilled, (state, action) => {
      editTimeSheetFulfilled(state, action);
    }),
    builder.addCase(editTimeSheet.rejected, (state, action) => {
      allCheckInOutRejected(state, action);
    }),
    builder.addCase(editDND.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    }),
    builder.addCase(editDND.fulfilled, (state, action) => {
      editDNDFulfilled(state, action);
    }),
    builder.addCase(editDND.rejected, (state, action) => {
      allCheckInOutRejected(state, action);
    }),
    builder.addCase(getIPAddress.fulfilled, (state, action) => {
      getIPAddressFulfilled(state, action);
    })
  ),
});

export const {
  setAttendanceSummary,
  reset,
  setSelectedAttendance,
  resetError,
} = timeClockSlice.actions;
