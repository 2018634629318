import { Box } from "@mui/material";
import totalDealerLogo from "images/total-dealer.png";

const Logo = () => {
  return (
    <Box
      component="div"
      sx={{
        backgroundImage: `url(${totalDealerLogo})`,
        backgroundSize: "contain",
        width: "171px",
        height: "52px",
        mb: 6,
      }}
    />
  );
};

export default Logo;
