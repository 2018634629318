import { LOCAL_STORAGE_KEYS } from "constants/index";
import { APP_ROUTES } from "constants/routes";
import { Redirect, Route } from "react-router-dom";
import AppShell from "AppShell";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const tokenExist = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) =>
        tokenExist ? (
          <AppShell>
            <Component {...props} />
          </AppShell>
        ) : (
          <Redirect to={APP_ROUTES.Login} />
        )
      }
    />
  );
};

export default ProtectedRoute;
