import React from "react";
import { Box, Stack, StackProps, SxProps, Typography } from "@mui/material";
import { CustomChip, ImageBox } from "components";
import { pxToRem, VehicalInfoCardStyle } from "constants/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

interface VehicleInfoCardProps extends StackProps {
  coverImg?: string;
  showCoverImg?: boolean;
  status?: string;
  title?: string;
  stockNumber?: number | string;
  vinNumber?: number | string;
  lotLocation?: string;
  customLabelChip?: React.ReactNode;
  iconButton?: React.ReactNode;
  sx?: SxProps;
  contentSx?: SxProps;
}

const VehicleInfoCard = (props: VehicleInfoCardProps) => {
  const {
    coverImg,
    showCoverImg = false,
    status,
    title = "New 2023 Forest River RV Rockwood Mini Lite 2104S",
    stockNumber = "193461",
    vinNumber = "4X4TRLW27PHG324BN",
    lotLocation,
    customLabelChip,
    iconButton,
    sx,
    contentSx,
  } = props;

  return (
    <Stack
      direction={"row"}
      sx={{
        ...VehicalInfoCardStyle,
        backgroundColor: "grey.100",
        ...sx,
      }}
    >
      {showCoverImg && (
        <Box>
          <ImageBox
            coverImg={coverImg}
            width={pxToRem(43)}
            height={pxToRem(43)}
            borderRadius={pxToRem(4)}
            sx={{ color: "grey.50" }}
          />
        </Box>
      )}
      <Box flex={1} sx={contentSx}>
        {status && (
          <Typography className="status">
            <CheckCircleRoundedIcon sx={{ mr: pxToRem(4) }} /> {status}
          </Typography>
        )}
        {title && <Typography className="title">{title}</Typography>}
        {stockNumber && <Typography>Stock#: {stockNumber}</Typography>}
        {vinNumber && <Typography>VIN#: {vinNumber}</Typography>}
        {lotLocation && (
          <CustomChip
            label={lotLocation}
            sx={{
              backgroundColor: "supportingColorAlt.A200",
              color: "grey.A400",
            }}
          />
        )}
      </Box>
      <Box display={"flex"} alignItems={iconButton ? "center" : "flex-end"}>
        {iconButton}
        {customLabelChip && customLabelChip}
      </Box>
    </Stack>
  );
};

export default VehicleInfoCard;
