import { TableComponent, LocationBox, AvatarWrapper, Button } from "components";
import SettingsIcon from "@mui/icons-material/Settings";
import { GridColDef } from "@mui/x-data-grid-premium";
import AddIcon from "@mui/icons-material/Add";
import ListViewHeader from "containers/security/listViewHeader";
import SecurityWrapper from "./SecurityWrapper";
import { LIST_VIEW_HEADER_TYPE } from "constants/index";
import { APP_ROUTES } from "constants/routes";
import { linkButtonGrey50, pxToRem } from "../../constants/styles";
import { useHistory } from "react-router-dom";
import SecurityDropdown from "./SecurityDropdown";
import useViewPersonnels from "./useViewPersonnels";
import { filterOperators } from "utils/common";

export const searchableColumns = ["userName", "roleName", "description"];

const Security = () => {
  const columns: GridColDef[] = [
    {
      field: "profilepic",
      headerName: "Img",
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      width: 65,
      renderCell: (params) => (
        <AvatarWrapper src={params.value} avatarSize="small" />
      ),
    },
    {
      field: "userName",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      filterOperators: filterOperators(),
      width: 130,
    },
    {
      field: "roleName",
      headerName: "Role",
      headerClassName: "super-app-theme--header",
      filterOperators: filterOperators(),
      sortable: false,
      width: 130,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      filterOperators: filterOperators(),
      sortable: false,
      width: 436,
      flex: 1,
    },
    {
      field: "tenantName",
      headerName: "Location",
      filterOperators: filterOperators(),
      width: 250,
      sortable: false,
      renderCell: (params) => <LocationBox dataSource={params} />,
    },
    {
      field: "action",
      width: Number(pxToRem(64)),
      headerClassName: "header-options",
      headerAlign: "right",
      cellClassName: "cell-options",
      align: "right",
      sortable: false,
      filterable: false,
      resizable: false,
      disableColumnMenu: true,
      hideable: false,
      renderHeader: (params) => <SettingsIcon />,
      renderCell: (params) => <SecurityDropdown dataSource={params} />,
    },
  ];

  const history = useHistory();
  const {
    personnels,
    isLoading,
    handleFilterClick,
    handleCheckboxClick,
    apiRef,
    searchHandler,
    page,
    setPage,
    totalRecords,
    handleViewClick,
    sortModel,
    handleSortModelChange,
    handleFilterModelChange,
  } = useViewPersonnels();

  return (
    <SecurityWrapper>
      <ListViewHeader
        listViewHeaderType={LIST_VIEW_HEADER_TYPE.PERSONNEL}
        createButton={
          <Button
            startIcon={<AddIcon />}
            variant="text"
            onClick={() => history.push(APP_ROUTES.EditSecurityPersonnel)}
            sx={{ ...linkButtonGrey50, marginRight: "18px" }}
          >
            Create user
          </Button>
        }
        handleFilter={handleFilterClick}
        searchHandler={searchHandler}
      />
      <TableComponent
        rows={personnels}
        columns={columns}
        handleCheckboxClick={handleCheckboxClick}
        loading={isLoading}
        apiRef={apiRef}
        setPage={setPage}
        page={page}
        totalRecords={totalRecords}
        isDyanmic={true}
        handleRowClick={(row) => handleViewClick(row.id)}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        handleFilterModelChange={handleFilterModelChange}
      />
    </SecurityWrapper>
  );
};

export default Security;
