const useStringInitials = (name: string): string => {
  if (!name) return "";
  return name
    .match(/\b(\w)/g)
    .slice(0, 2)
    .map((char) => char.toUpperCase())
    .join("");
};

export default useStringInitials;
