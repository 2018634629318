import React from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Stack, Typography, Menu, MenuItem } from "@mui/material";
import { pxToRem } from "constants/styles";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "constants/routes";
import encodeParams from "utils/urlObjectEncoder";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SecurityDropdown = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = {
    userName: props.dataSource.row.userName,
    userRole: props.dataSource.row.roleName,
    userImg: props.dataSource.row.profilepic,
    id: props.dataSource.row.id,
  };

  const handleViewClick = () => {
    const queryParams = {
      id: props.dataSource.row.id,
    };
    const encodedParams = encodeParams(queryParams);
    history.push(`${APP_ROUTES.ViewSecurityPersonnel}?${encodedParams}`);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-label="more"
        id="security-more"
        aria-controls={open ? "security-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="security-menu"
        MenuListProps={{
          "aria-labelledby": "security-more",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            history.push(APP_ROUTES.MergeSecurityPersonnel, { data })
          }
        >
          <Stack
            spacing={pxToRem(12)}
            direction="row"
            alignItems="center"
            sx={{ p: "8px 0" }}
          >
            <ExitToAppIcon />
            <Typography
              component="span"
              sx={{ fontSize: "14px", fontWeight: 400, letterSpacing: "0.5px" }}
            >
              Merge Personnel
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleViewClick}>
          <Stack
            spacing={pxToRem(12)}
            direction="row"
            alignItems="center"
            sx={{ p: "8px 0" }}
          >
            <VisibilityIcon />
            <Typography
              component="span"
              sx={{ fontSize: "14px", fontWeight: 400, letterSpacing: "0.5px" }}
            >
              View Personnel
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SecurityDropdown;
