import { FC } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { datePickerStyle } from "constants/styles";
import { convertToDayjsDate } from "utils/common";

type DatePickerWrapperPropType = {
  name: string;
  label?: string;
  value?: any;
  onChange: (value: any) => void;
};

const DatePickerWrapper: FC<DatePickerWrapperPropType> = (props) => {
  const {
    name,
    label = "Date",
    value = convertToDayjsDate(new Date().toISOString()),
    onChange,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slotProps={{
          textField: {
            name: name,
          },
        }}
        label={label}
        value={value}
        onChange={onChange}
        sx={{ ...datePickerStyle, width: "100%" }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerWrapper;
