import { Box, BoxProps } from "@mui/material";
import { useMemo } from "react";
import useUpdateDynamicHeight from "utils/hooks/useUpdateDynamicHeight";

interface Props extends BoxProps {
  children: React.ReactNode;
  isFullPageView?: boolean;
  minusHeight?: number;
  sx?: object;
}

const PageBody = (props: Props) => {
  const {
    sx,
    children,
    isFullPageView = false,
    minusHeight,
    ...otherProps
  } = props;
  const { dynamicHeightRef, dynamicHeight } =
    useUpdateDynamicHeight(minusHeight);
  const pageStyle = useMemo(
    () => ({ maxHeight: dynamicHeight, overflow: "auto" }),
    [dynamicHeight]
  );
  return (
    <Box
      ref={dynamicHeightRef}
      sx={{
        ...(isFullPageView && pageStyle),
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default PageBody;
