import { useEffect, useState } from "react";
import { MENU_ITEMS as menu } from "constants/routes";
import { useLocation } from "react-router-dom";

const useSidebarLink = (item) => {
  const location = useLocation();
  const [itemIsActive, setItemIsActive] = useState(false);

  useEffect(() => {
    if (menu.length) {
      const activeIndex = menu.findIndex(
        (item) => item.link === location.pathname
      );
      if (activeIndex !== -1) {
        setItemIsActive(item.index === menu[activeIndex].index);
      } else {
        const parentItem = menu.findIndex((item) =>
          item.childLInks
            .map((childLink) => childLink.link)
            .find((link) => link === location.pathname)
        );
        if (item.index === menu[parentItem]?.index)
          setItemIsActive(item.index === menu[parentItem].index);
      }
    }
  }, [location.pathname, item.index]);

  return itemIsActive;
};

export default useSidebarLink;
