import { Box } from "@mui/material";
import { DividerAccordion } from "./../index";
import { TableWrapperStyle } from "constants/styles";

interface Props {
  tableFilterWrapper?: React.ReactNode;
  children?: React.ReactNode;
  sx?: object;
  tableFilterWrapperSx?: object;
}

const TableWrapper = (props: Props) => {
  const { children, tableFilterWrapper, sx, tableFilterWrapperSx } = props;

  return (
    <Box sx={{ ...TableWrapperStyle, ...sx }}>
      {tableFilterWrapper && (
        <Box sx={tableFilterWrapperSx}>
          <DividerAccordion>{tableFilterWrapper}</DividerAccordion>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default TableWrapper;
