import PortraitIcon from "@mui/icons-material/Portrait";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Input, Button, FileDropzone } from "components";
import useBulkDialog from "./useBulkDialog";
import { pxToRem } from "constants/styles";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BulkImportDialog = ({ onClose }) => {
  const {
    fileName,
    errorFile,
    filenameHandler,
    dropzoneHandler,
    title,
    note,
    infoNote,
    description,
    confirm,
    browse,
    cancel,
    isLoading,
    handleFileSubmit,
  } = useBulkDialog();

  return (
    <>
      <DialogTitle>
        <PortraitIcon />
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography
          pb={pxToRem(15)}
          fontWeight={500}
          letterSpacing={pxToRem(0.15)}
        >
          {description}
        </Typography>
        <Stack direction="row" spacing={pxToRem(18)}>
          <Input
            fullWidth={true}
            placeholder="CSV"
            name="Brows file"
            value={!errorFile ? fileName : ""}
            hiddenLabel
            error={errorFile}
            helperText={errorFile && fileName}
          />
          <Box>
            <Button
              component="label"
              sx={{
                width: pxToRem(101),
                paddingY: pxToRem(10),
                boxShadow: "none !important",
              }}
            >
              {browse}
              <VisuallyHiddenInput
                type="file"
                accept=".csv"
                onChange={filenameHandler}
              />
            </Button>
          </Box>
        </Stack>

        <FileDropzone
          onFileDrop={dropzoneHandler}
          fileName={fileName}
          errorFile={errorFile}
        />
        <Typography
          sx={{
            color: "grey.100",
            lineHeight: "normal",
            letterSpacing: pxToRem(0.5),
          }}
        >
          {note}
        </Typography>
        <Typography
          sx={{
            color: "grey.100",
            fontSize: pxToRem(12),
            lineHeight: "normal",
            letterSpacing: pxToRem(0.25),
          }}
        >
          {infoNote}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancel}</Button>
        <Button
          disabled={errorFile || !fileName ? true : false || isLoading}
          onClick={handleFileSubmit}
        >
          {confirm}
        </Button>
      </DialogActions>
    </>
  );
};

export default BulkImportDialog;
