import { useEffect } from "react";
import useLogout from "utils/hooks/useLogout";

const Logout = () => {
  const { handleLogout } = useLogout();

  useEffect(
    () => {
      handleLogout();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return null;
};

export default Logout;
