import ImageBox from "components/ImageBox";
import { pxToRem } from "constants/styles";
import Slider, { Settings } from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export type SlickSliderPropType = {
  setting?: Settings;
  images?: string[];
  afterSlideChange: (current: number) => void;
  width?: string;
  height?: string;
  arrows?: boolean;
};

const SlickSlider = (props: SlickSliderPropType) => {
  const {
    setting,
    images,
    afterSlideChange,
    width = "100%",
    height = pxToRem(140),
    arrows = true,
  } = props;

  const settings: Settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowBackIosIcon sx={{ color: "secondary.main" }} />,
    nextArrow: <ArrowForwardIosIcon sx={{ color: "secondary.main" }} />,
    afterChange: afterSlideChange,
    ...setting,
  };

  return (
    <Slider {...settings} arrows={arrows}>
      {images?.map((img) => (
        <ImageBox
          coverImg={img ? `"${img}"` : null}
          backgroundSize={"contain"}
          width={width}
          height={height}
        />
      ))}
    </Slider>
  );
};

export default SlickSlider;
